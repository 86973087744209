import { useState } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './BroadcastMainBannerList.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCreative } from 'swiper';

type BroadcastMainBannerListProps = {
    bannerList: any;
};

function BroadcastMainBannerList({ bannerList }: BroadcastMainBannerListProps) {
    const delay = 3000;
    let bannerImageList: string[] = [];

    for (let i = 0; i < bannerList.length; i++) {
        bannerImageList.push(`${bannerList[i].img}&w=500`);
    }

    const [indexState, setIndexState] = useState<number>(1);
    const [lengthState, setLengState] = useState<number>(bannerImageList.length);

    return (
        <BannerWrap>
            <Swiper
                loop={true}
                autoplay={{
                    delay: delay,
                    disableOnInteraction: false,
                }}
                grabCursor={true}
                centeredSlides={true}
                effect={'creative'}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                    },
                    next: {
                        translate: ['100%', 0, 0],
                    },
                }}
                modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                onSlideChange={(swiper) => setIndexState(swiper.realIndex + 1)}
            >
                {bannerImageList.map((item: string) => {
                    return (
                        <SwiperSlide key={item}>
                            <img src={item} width={'100%'} height={'100%'} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <CountWrap>
                <CountSpan>{`${indexState} / ${lengthState}`}</CountSpan>
            </CountWrap>
        </BannerWrap>
    );
}

export default BroadcastMainBannerList;

//
const BannerWrap = styled.div`
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${(props) => props.theme.bgColor.primary};
    @media (max-width: 430px) {
        height: 400px;
    }
`;

const CountWrap = styled.div`
    z-index: 1;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    right: 15px;
    bottom: 15px;
    width: 70px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 4px;
    padding-bottom: 6px;
    border-radius: 20px;
    border: solid white 1px;
    overflow: hidden;
    @media (max-width: 430px) {
        right: 8px;
        bottom: 10px;
        width: 55px;
        padding: 2px 8px;
    }
`;

const CountSpan = styled.span`
    color: #ffffff;
    font-size: 1.1em;
    @media (max-width: 430px) {
        font-size: 0.95em;
    }
`;
