import { Fragment } from 'react';
import styled from 'styled-components';
import { CarPriceStatus, NumberCommaFormat } from '../../common/Util';
import defaultCarImage from '../../../assets/images/defaultImage.jpg';

type HiddenCarDetailInfoNavBarProps = {
    originData: any;
};

function HiddenCarDetailInfoNavBar({ originData }: HiddenCarDetailInfoNavBarProps) {
    const carData = originData.result.data[0];
    const carSaleType = originData.result.data[0].sale_type;
    const carImage = carData.img1.includes('d2rxlfguhx92xl') ? `${carData.img1}&w=300` : carData.img1;

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    return (
        <HiddenCarDetailInfoNav>
            <div style={{ width: 130, height: 100, borderRadius: 5, overflow: 'hidden' }}>
                <img src={carImage} width={130} height={100} onError={handleImgOnError} loading={'eager'} />
            </div>
            <div style={{ width: '65%', paddingLeft: '0.8em' }}>
                <div style={{ height: '48%', display: 'flex', alignItems: 'center' }}>
                    <TitleSapn>{carData.title}</TitleSapn>
                </div>
                <div>
                    {carSaleType === '1' ? (
                        <span style={{ color: 'gray', fontSize: '0.75em' }}>
                            {carData.ym}&nbsp;·&nbsp;
                            {NumberCommaFormat(carData.mileage)}km&nbsp;·&nbsp;
                            {carData.fuel}&nbsp;·&nbsp;
                            {carData.si}
                        </span>
                    ) : (
                        <span style={{ color: 'gray', fontSize: '0.75em' }}>
                            {carData.ym}&nbsp;·&nbsp;
                            {carData.fuel}&nbsp;·&nbsp;
                            {carData.si}
                        </span>
                    )}
                </div>
                <div style={{ marginTop: '0.3em' }}>
                    <span style={{ fontSize: '1em' }}>
                        <CarPriceStatus item={originData.result.data[0]} />
                    </span>
                </div>
            </div>
        </HiddenCarDetailInfoNav>
    );
}

const TitleSapn = styled.span`
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${(props) => props.theme.text.primary};
    font-size: 1.1em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
    @media (max-width: 430px) {
        font-size: 1em;
    }
`;

const HiddenCarDetailInfoNav = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 0.45em 0.5em;
    background-color: ${(props) => props.theme.bgColor.primary};
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 50px;
    z-index: 10;
    opacity: 1;
    animation: fadein 0.5s linear forwards;
    box-shadow: ${(props) => (props.theme.state === 'light' ? `0 0.3em 0em 0 rgb(0 0 0 / 20%)` : ``)};

    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes fadeout {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export default HiddenCarDetailInfoNavBar;
