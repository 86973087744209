// 믿차
const mcTestCode: string = 'mc'; // gclid
// 빅차고유식별키
const bigchaIdentifierCode: string = 'uIfthDsWb5x1B60';

const PageIdentifierIdArr: string[] = [mcTestCode, bigchaIdentifierCode];

export function checkPageIdentifier(id: string): boolean {
    for (let i = 0; i < PageIdentifierIdArr.length; i++) if (id === PageIdentifierIdArr[i]) return true;
    return false;
}
