import { forwardRef, Fragment } from 'react';
import styled from 'styled-components';
import { CarPriceStatus } from '../../common/Util';

type CarBasicInfoProps = {
    originData: any;
};

const CarBasicInfo = forwardRef(({ originData }: CarBasicInfoProps, ref: any) => {
    const carData = originData.result.data[0];
    return (
        <div ref={ref} style={{ width: '100%' }}>
            <div>
                <TitleSapn>{carData.title}</TitleSapn>
            </div>
            <div style={{ marginTop: 10 }}>
                <YmSiSpan>
                    {carData.sale_type === '1' ? (
                        <Fragment>
                            {carData.ym}&nbsp;·&nbsp;
                            {carData.mileage}
                            km&nbsp;·&nbsp;
                            {carData.fuel}&nbsp;·&nbsp;
                            {carData.si}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {carData.ym}&nbsp;·&nbsp;
                            {carData.fuel}&nbsp;·&nbsp;
                            {carData.si}
                        </Fragment>
                    )}
                </YmSiSpan>
            </div>
            <div style={{ marginTop: 7 }}>
                <PriceSpan>
                    <CarPriceStatus item={carData} />
                </PriceSpan>
            </div>
        </div>
    );
});

const TitleSapn = styled.h1`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.4em;
    @media (max-width: 430px) {
        font-size: 1.3em;
    }
`;

const PriceSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.4em;
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.38em;
    }
`;

const YmSiSpan = styled.span`
    color: gray;
    font-size: 1.08em;
    @media (max-width: 430px) {
        font-size: 1.05em;
    }
`;

export default CarBasicInfo;
