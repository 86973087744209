import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Caretdown16 } from '../../../assets/images/caretdown_16.svg';
import { ReactComponent as Caretup16 } from '../../../assets/images/caretup_16.svg';
import { ReactComponent as Certificate } from '../../../assets/images/certificate.svg';

type CarDealerInfoProps = {
    themeMode: string;
    originData: any;
};

function CarDealerInfo({ themeMode, originData }: CarDealerInfoProps) {
    const carData = originData.result.data[0];
    const carDealerInfo = originData.result.seller;
    const [textMoreVisiable, setTextMoreVisiable] = useState<boolean>(false);

    function encodeHtml(val: string) {
        if (val == null) return '';
        val = val
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&quot;/g, "'");
        return val;
    }

    function stripJsonTag(val: string) {
        if (val == null) return '';
        val = val.replace('<![CDATA[', '');
        val = val.replace(']]>', '');
        return val;
    }

    function stripHtmlTag(val: string) {
        if (val == null) return '';
        val = val.replace(/<br \/>/gi, '');
        val = val.replace(/\&nbsp; /gi, '');
        val = val.replace(/\&nbsp;/gi, '');
        val = val.replace(/<[^>]*>?/gi, '');
        return val;
    }

    function forSaleDesc(val: string) {
        var replaceText = stripJsonTag(val);
        replaceText = encodeHtml(replaceText);
        replaceText = stripHtmlTag(replaceText);
        return replaceText;
    }

    const carMainTextLineNum = forSaleDesc(carData.main).split('\n').length - 1;

    function DoMore() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '0.8em',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setTextMoreVisiable(true);
                }}
            >
                <StateSpan>더보기</StateSpan>
                <Caretdown16 stroke={themeMode === 'light' ? '#000000' : '#FFFFFF'} />
            </div>
        );
    }

    function DoLess() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '0.8em',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setTextMoreVisiable(false);
                }}
            >
                <StateSpan>접기</StateSpan>
                <Caretup16 stroke={themeMode === 'light' ? '#000000' : '#FFFFFF'} />
            </div>
        );
    }

    const DealerInfoItem = () => {
        if (carData && carDealerInfo.user_id !== '') {
            // 믿차 인증
            if (carData.option_str.indexOf('opendealer2') > -1 || carDealerInfo.extra15 === '1') {
                return (
                    <div>
                        <ItemWarp>
                            <div>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            borderRadius: 100,
                                            overflow: 'hidden',
                                            width: 65,
                                            height: 65,
                                        }}
                                    >
                                        <img
                                            src={
                                                carDealerInfo.photo1
                                                    ? carDealerInfo.photo1.includes('d2rxlfguhx92xl')
                                                        ? carDealerInfo.photo1 + '&w=100'
                                                        : carDealerInfo.photo1
                                                    : `${process.env.NEXT_PUBLIC_LIVECOMMERCE_API_URL}/static/images/bg_profile.png`
                                            }
                                            width={'100%'}
                                            height={'100%'}
                                        />
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center', marginTop: '0.5em' }}>
                                    <CenterSpan>{carDealerInfo.user_nick}</CenterSpan>
                                </div>
                                <MeetchaAuthenticationWrap>
                                    <Certificate />
                                    <MeetchaAuthenticationSpan>믿차인증</MeetchaAuthenticationSpan>
                                </MeetchaAuthenticationWrap>
                            </div>
                        </ItemWarp>

                        {!textMoreVisiable ? (
                            <ShowMoreText>{forSaleDesc(carData.main)}</ShowMoreText>
                        ) : (
                            <ShowLessText>{forSaleDesc(carData.main)}</ShowLessText>
                        )}

                        {carMainTextLineNum > 5 ? !textMoreVisiable ? <DoMore /> : <DoLess /> : null}
                    </div>
                );
            } else {
                // 해피콜
                if (carData.group1 === '렌트' || carData.group1 === '리스') {
                    return (
                        <div>
                            <ItemWarp>
                                <div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                borderRadius: 100,
                                                overflow: 'hidden',
                                                width: 65,
                                                height: 65,
                                            }}
                                        >
                                            <img
                                                src={`${process.env.NEXT_PUBLIC_LIVECOMMERCE_API_URL}/static/images/logo.png`}
                                                width={65}
                                                height={65}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <CenterSpan>해피콜</CenterSpan>
                                    </div>
                                    <MeetchaAuthenticationWrap>
                                        <Certificate />
                                        <MeetchaAuthenticationSpan>믿차인증</MeetchaAuthenticationSpan>
                                    </MeetchaAuthenticationWrap>
                                </div>
                            </ItemWarp>

                            {!textMoreVisiable ? (
                                <ShowMoreText>{forSaleDesc(carData.main)}</ShowMoreText>
                            ) : (
                                <ShowLessText>{forSaleDesc(carData.main)}</ShowLessText>
                            )}
                            {carMainTextLineNum > 5 ? !textMoreVisiable ? <DoMore /> : <DoLess /> : null}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <ItemWarp>
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img
                                            src={
                                                'https://meetcha-resource-theme-repository.s3.ap-northeast-2.amazonaws.com/meetcha_profile.png'
                                            }
                                            width={70}
                                            height={70}
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <CenterSpan>해피콜</CenterSpan>
                                    </div>
                                    <MeetchaAuthenticationWrap>
                                        <Certificate />
                                        <MeetchaAuthenticationSpan>믿차인증</MeetchaAuthenticationSpan>
                                    </MeetchaAuthenticationWrap>
                                </div>
                            </ItemWarp>

                            {!textMoreVisiable ? (
                                <ShowMoreText>
                                    믿고만나는 중고차, Meetcha
                                    <br />
                                    허위매물? 그게뭔데?
                                    <br />
                                    눈으로 직접보고 소통하는
                                    <br />
                                    신개념 라이브커머스 중고차 플랫폼
                                    <br />
                                    실시간 차량영상을 통해 궁금한 건 바로 소통!
                                    <br />
                                    신뢰할 수 있는 중고차 시장,
                                    <br />
                                    믿고 만나는 중고차 Meetcha가
                                    <br />
                                    함께 합니다.
                                    <br />
                                    해피콜 상담 <ShowMoreSpan> 02-1688-4965</ShowMoreSpan>
                                </ShowMoreText>
                            ) : (
                                <ShowLessText>
                                    믿고만나는 중고차, Meetcha
                                    <br />
                                    허위매물? 그게뭔데?
                                    <br />
                                    눈으로 직접보고 소통하는
                                    <br />
                                    신개념 라이브커머스 중고차 플랫폼
                                    <br />
                                    실시간 차량영상을 통해 궁금한 건 바로 소통!
                                    <br />
                                    신뢰할 수 있는 중고차 시장,
                                    <br />
                                    믿고 만나는 중고차 Meetcha가
                                    <br />
                                    함께 합니다.
                                    <br />
                                    해피콜 상담 <ShowMoreSpan> 02-1688-4965</ShowMoreSpan>
                                </ShowLessText>
                            )}
                            {!textMoreVisiable ? <DoMore /> : <DoLess />}
                        </div>
                    );
                }
            }
        }
        return <Fragment />;
    };

    return (
        <div style={{ width: '100%', marginTop: '5em' }}>
            {/* 타이틀 */}
            <div>
                <DealerInfoSpan>딜러정보</DealerInfoSpan>
            </div>
            {/* 딜러사진 & 메인글 */}
            <div style={{ width: '100%', marginTop: '1em' }}>
                <DealerInfoItem />
            </div>
        </div>
    );
}

const DealerInfoSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const ItemWarp = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
`;

const CenterSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 0.9em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const MeetchaAuthenticationWrap = styled.div`
    margin-top: 0.3em;
    border-radius: 20px;
    padding: 5px 10px;
    background-color: #a42bf5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MeetchaAuthenticationSpan = styled.span`
    color: #ffffff;
    margin-bottom: 0.15em;
    margin-left: 0.3em;
    font-size: 0.75em;
`;

const ShowMoreText = styled.div`
    color: ${(props) => props.theme.text.primary};
    margin-top: 4em;
    font-size: 0.92em;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
    @media (max-width: 430px) {
        font-size: 0.87em;
    }
`;

const ShowLessText = styled.div`
    color: ${(props) => props.theme.text.primary};
    margin-top: 4em;
    font-size: 0.92em;
    white-space: pre-wrap;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
    @media (max-width: 430px) {
        font-size: 0.87em;
    }
`;

const ShowMoreSpan = styled.span`
    padding-top: 5px;
    font-size: 1.0485em;
    color: #ff436c;
`;

const StateSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 0.95em;
    font-weight: bold;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

export default CarDealerInfo;
