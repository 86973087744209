import useSWR from 'swr';
import axios from 'axios';
import './BigchaMainCarList.css';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { getThemeState, useMclid } from '../../context/Providers';
import { meetchaBroadcastListAPI } from '../../api/MeetchaAPI';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import defaultCarImage from '../../assets/images/defaultImage.jpg';
import { NumberCommaFormat } from '../../components/common/Util';
import { useNavigate } from 'react-router-dom';

type GetCarPriceProps = {
    item: any;
};

async function fetcher(url: string) {
    const res = await axios.get(url);
    return res.data.broadcastList;
}

const bcLight = {
    bgColor: '#FFFFFF',
    titleColor: '#273247',
    secondTitleColor: '#777777',
    carTitleColor: '#111111',
    carPriceColor: '#111111',
    btnColor: '#999999',
    bottomColor: 'rgba(0, 0, 0, 0.1)',
};

const bcDark = {
    bgColor: '#000000',
    titleColor: '#ffffff',
    secondTitleColor: '#cac8c8',
    carTitleColor: '#ffffff',
    carPriceColor: '#ffffff',
    btnColor: '#ffffff',
    bottomColor: 'rgba(255, 255, 255, 0.2)',
};

const bigchaDefaultTheme = {
    bigchaColPadd: '1.5rem',
};

const bcLightTheme = { ...bigchaDefaultTheme, ...bcLight };
const bcDarkTheme = { ...bigchaDefaultTheme, ...bcDark };

function BigchaMainCarList() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [theme, setTheme] = useState(query.theme! === 'light' ? 'light' : 'dark');
    const [itemPosition, setItemPosition] = useState(query.itemPosition! === 'left' ? 'left' : 'right');
    const [rowSize, setRowSize] = useState(query.rowSize! ? query.rowSize : 3);
    const themeMode = getThemeState();
    const [mclid, settingMclid] = useMclid();
    const themeObject = theme === 'dark' ? bcDarkTheme : bcLightTheme;
    const rowSizeArr: string[] = [];

    for (let i = 0; i < rowSize; i++) {
        rowSizeArr.push(`${i}`);
    }

    const { data, error } = useSWR(
        `${meetchaBroadcastListAPI}?searchType=search&howtoSort=broadcastStartDt&page=0`,
        fetcher,
    );

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    function goBroadList() {
        // navigate(`/broadcast/list?mclid=${mclid}&theme=${themeMode}`);
        window.location.href = `/broadcast/list?mclid=${mclid}&theme=${themeMode}`;
    }

    function GetCarPrice({ item }: GetCarPriceProps) {
        if (item.saleEnd !== 0) {
            return <PanmaeanlyoSpanItem themeObject={themeObject}>판매완료</PanmaeanlyoSpanItem>;
        } else {
            if (item.group1 === '렌트') {
                return (
                    <Fragment>
                        <WolSpanItem themeObject={themeObject}>월 </WolSpanItem>
                        <PriceSpanItem themeObject={themeObject}>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                        <ManWonSpanItem themeObject={themeObject}>만원</ManWonSpanItem>
                        <GubunSpanItem themeObject={themeObject}> (렌터카)</GubunSpanItem>
                    </Fragment>
                );
            } else if (item.group1 === '리스') {
                return (
                    <Fragment>
                        <WolSpanItem themeObject={themeObject}>월 </WolSpanItem>
                        <PriceSpanItem themeObject={themeObject}>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                        <ManWonSpanItem themeObject={themeObject}>만원</ManWonSpanItem>
                        <GubunSpanItem themeObject={themeObject}> ({item.group1})</GubunSpanItem>
                    </Fragment>
                );
            } else if (item.saleType === '5') {
                return (
                    <Fragment>
                        <WolSpanItem themeObject={themeObject}>월 </WolSpanItem>
                        <PriceSpanItem themeObject={themeObject}>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                        <ManWonSpanItem themeObject={themeObject}>만원</ManWonSpanItem>
                        <GubunSpanItem themeObject={themeObject}> (구독)</GubunSpanItem>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <PriceSpanItem themeObject={themeObject}>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                        <ManWonSpanItem themeObject={themeObject}>만원</ManWonSpanItem>
                    </Fragment>
                );
            }
        }
    }

    return (
        <AllWrap themeObject={themeObject}>
            <Wrap themeObject={themeObject}>
                <TitleWrap themeObject={themeObject}>
                    <H2 themeObject={themeObject}>믿차</H2>
                    <H2Txt themeObject={themeObject}>믿고 만나는 라이브 중고차</H2Txt>
                </TitleWrap>
                <UsedCarList>
                    {data && !error
                        ? data.slice(0, rowSize).map((item: any) => {
                              return (
                                  <UsedCarItem key={item.seq} themeObject={themeObject} onClick={goBroadList}>
                                      <CarItem position={itemPosition}>
                                          {itemPosition === 'left' ? (
                                              <Fragment>
                                                  <CarImg>
                                                      <img
                                                          src={
                                                              item.carList[0].img1.includes('d2rxlfguhx92xl')
                                                                  ? `${item.carList[0].img1}&w=200`
                                                                  : item.carList[0].img1
                                                          }
                                                          width={'100%'}
                                                          height={'100%'}
                                                          onError={handleImgOnError}
                                                      />
                                                  </CarImg>
                                                  <CarInfo position={itemPosition}>
                                                      <CarTitle themeObject={themeObject}>{item.title}</CarTitle>
                                                      <CarPrice themeObject={themeObject}>
                                                          {/* {NumberCommaFormat(String(item.carList[0].price))}만원 */}
                                                          {NumberCommaFormat(String(item.carList[0].price))}만원
                                                      </CarPrice>
                                                  </CarInfo>
                                              </Fragment>
                                          ) : (
                                              <Fragment>
                                                  <CarInfo position={itemPosition}>
                                                      <CarTitle themeObject={themeObject}>{item.title}</CarTitle>
                                                      <CarPrice themeObject={themeObject}>
                                                          {/* {NumberCommaFormat(String(item.carList[0].price))}만원 */}
                                                          <GetCarPrice item={item.carList[0]} />
                                                      </CarPrice>
                                                  </CarInfo>
                                                  <CarImg>
                                                      <img
                                                          src={
                                                              item.carList[0].img1.includes('d2rxlfguhx92xl')
                                                                  ? `${item.carList[0].img1}&w=200`
                                                                  : item.carList[0].img1
                                                          }
                                                          width={'100%'}
                                                          height={'100%'}
                                                          onError={handleImgOnError}
                                                      />
                                                  </CarImg>
                                              </Fragment>
                                          )}
                                      </CarItem>
                                  </UsedCarItem>
                              );
                          })
                        : rowSizeArr.map((item: any) => {
                              return (
                                  <UsedCarItem key={item} themeObject={themeObject}>
                                      <CarItem>
                                          <CarImg />
                                      </CarItem>
                                  </UsedCarItem>
                              );
                          })}
                </UsedCarList>
                <BntMoreWrap>
                    <BntEmpty />
                    <BntMore themeObject={themeObject} onClick={goBroadList}>
                        전체보기
                    </BntMore>
                </BntMoreWrap>
            </Wrap>
        </AllWrap>
    );
}
const AllWrap = styled.div.attrs((props: { themeObject: any }) => props)`
    background-color: ${(props) => props.themeObject.bgColor};
    width: 100%;
    height: 100vh;
`;

const Wrap = styled.div.attrs((props: { themeObject: any }) => props)`
    position: relative;
    width: 100%;
    background-color: ${(props) => props.themeObject.bgColor};
    padding: 3.7rem 0 0rem;
    padding-right: ${(props) => props.themeObject.bigchaColPadd};
    padding-left: ${(props) => props.themeObject.bigchaColPadd};
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const TitleWrap = styled.div.attrs((props: { themeObject: any }) => props)`
    padding-bottom: 1.4rem;
    border-bottom: ${(props) => `1px solid ${props.themeObject.bottomColor}`};
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const H2 = styled.h2.attrs((props: { themeObject: any }) => props)`
    color: ${(props) => props.themeObject.titleColor};
    display: block;
    margin: 0;
    padding: 0 0 6px;
    line-height: 1;
    font-size: 1.5rem;
    font-weight: bold;
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const H2Txt = styled.p.attrs((props: { themeObject: any }) => props)`
    color: ${(props) => props.themeObject.secondTitleColor};
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
    letter-spacing: -1px;
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const UsedCarList = styled.ul`
    width: 100%;
    padding: 0;
    margin: 0;
`;

const UsedCarItem = styled.li.attrs((props: { themeObject: any }) => props)`
    cursor: pointer;
    width: 100%;
    padding: 0.8rem 0;
    border-bottom: ${(props) => `1px solid ${props.themeObject.bottomColor}`};
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const CarItem = styled.div.attrs((props: { position: string }) => props)`
    display: flex;
    justify-content: ${(props) => (props.position === 'left' ? null : 'space-between')};
    align-items: center;
    cursor: pointer;
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const CarImg = styled.div`
    /* width: 122.81px; */
    width: 102.81px;
    height: 70.68px;
    border-radius: 4px;
    overflow: hidden;
    /* @media (max-width: 414px) {
    }
    @media (max-width: 360px) {
    } */
`;

const CarInfo = styled.div.attrs((props: { position: string }) => props)`
    width: calc(100% - 117.81px);
    padding-left: ${(props) => (props.position === 'left' ? '0.7rem' : null)};
`;

const CarTitle = styled.p.attrs((props: { themeObject: any }) => props)`
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${(props) => props.themeObject.carTitleColor};
    font-size: 1em;
    @media (max-width: 417px) {
        font-size: 0.95em;
    }
`;

const CarPrice = styled.span.attrs((props: { themeObject: any }) => props)`
    color: ${(props) => props.themeObject.carPriceColor};
    font-size: 1.1rem;
    font-weight: bold;
`;

const BntMoreWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
`;

const BntEmpty = styled.div``;

const BntMore = styled.span.attrs((props: { themeObject: any }) => props)`
    cursor: pointer;
    padding: 15px 0;
    color: ${(props) => props.themeObject.btnColor};
    width: auto;
    background: transparent;
    display: inline-block;
    font-size: 0.9rem;
    text-decoration: underline;
    font-weight: normal;
    font-size: 0.9em;
`;

// 가격
const WolSpanItem = styled.span.attrs((props: { themeObject: any }) => props)`
    font-size: 1rem;
    color: ${(props) => props.themeObject.carPriceColor};
    font-weight: bold;
`;

const PriceSpanItem = styled.span.attrs((props: { themeObject: any }) => props)`
    color: ${(props) => props.themeObject.carPriceColor};
    font-weight: bold;
`;

const ManWonSpanItem = styled.span.attrs((props: { themeObject: any }) => props)`
    font-size: 1rem;
    color: ${(props) => props.themeObject.carPriceColor};
    font-weight: bold;
`;

const GubunSpanItem = styled.span.attrs((props: { themeObject: any }) => props)`
    font-size: 0.9rem;
    color: ${(props) => props.themeObject.carPriceColor};
`;

const PanmaeanlyoSpanItem = styled.span.attrs((props: { themeObject: any }) => props)`
    color: ${(props) => props.themeObject.carPriceColor};
    font-weight: bold;
`;

export default BigchaMainCarList;
