import styled from 'styled-components';
import { SetStateAction } from 'react';
import { isMobile } from 'react-device-detect';
import { CarPriceStatus2 } from '../../common/Util';
import { ReactComponent as X } from '../../../assets/images/x_origin.svg';
import { ReactComponent as Eye } from '../../../assets/images/eye_thin.svg';
import { ReactComponent as Call } from '../../../assets/images/flat-call.svg';
import defaultCarImage from '../../../assets/images/defaultImage.jpg';
import { useNavigate } from 'react-router-dom';

type FirstDetailScreenProps = {
    originData: any;
    screenChangeToggle: boolean;
    setScreenChangeToggle: (value: SetStateAction<boolean>) => void;
    moreTitle: boolean;
    setMoreTitle: (value: SetStateAction<boolean>) => void;
    carListModalVisible: boolean;
    setCarListModalVisible: (value: SetStateAction<boolean>) => void;
};

function FirstDetailScreen({
    originData,
    screenChangeToggle,
    setScreenChangeToggle,
    moreTitle,
    setMoreTitle,
    carListModalVisible,
    setCarListModalVisible,
}: FirstDetailScreenProps) {
    const navigate = useNavigate();
    const photo = originData.photo.includes('d2rxlfguhx92xl') ? originData.photo + '&w=500' : originData.photo;
    const title = originData.title;
    const useNick = originData.userNick;
    const hitCount = originData.hitCount;
    const mainCar = originData.carList[0];
    const userPhone =
        originData.carList[0].userPhone !== '' ? originData.carList[0].userPhone : originData.carList[0].userHphone;

    function closeClick() {
        window.close();
        navigate(-1);
    }

    function parentClickPrevent(e: any) {
        e.stopPropagation();
    }

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    function onCarListModal() {
        if (!carListModalVisible) document.body.style.overflow = 'hidden';
        setCarListModalVisible((prev) => !prev);
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            onClick={() => {
                setScreenChangeToggle((prev) => !prev);
            }}
        >
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <TopAllWrap onClick={parentClickPrevent}>
                    <TopWrap>
                        {/* 포토 */}
                        <ProfileImgWrap>
                            <ProfileImg>
                                <img src={photo} width={'100%'} height={'100%'} />
                            </ProfileImg>
                            <VodStatusWrap>VOD</VodStatusWrap>
                        </ProfileImgWrap>
                        {/* 타이틀, 조회수 */}
                        <TitleWrap>
                            <TitleSpan
                                isMore={moreTitle}
                                onClick={() => {
                                    setMoreTitle((prev) => !prev);
                                }}
                            >
                                {title}
                            </TitleSpan>
                            <HitCountWrap>
                                <NickSpan>{useNick}</NickSpan>
                                <HitCountImgWrap>
                                    <Eye width={'100%'} height={'100%'} fill={'gray'} />
                                </HitCountImgWrap>
                                <HitCountSpan>{hitCount}</HitCountSpan>
                            </HitCountWrap>
                        </TitleWrap>
                        {/* 닫기 */}
                        <CloseImgWrap>
                            <X width={'100%'} height={'100%'} onClick={closeClick} />
                        </CloseImgWrap>
                    </TopWrap>
                </TopAllWrap>

                <BottomAllWrap onClick={parentClickPrevent}>
                    <BottomWrap>
                        <CarWrap onClick={onCarListModal}>
                            {/* 차량이미지 */}
                            <CarImgWrap>
                                <img
                                    src={
                                        mainCar.img1.includes('d2rxlfguhx92xl') ? `${mainCar.img1}&w=200` : mainCar.img1
                                    }
                                    width={'100%'}
                                    height={'100%'}
                                    onError={handleImgOnError}
                                />
                            </CarImgWrap>
                            {/* 차량정보 */}
                            <CarTitleWrap>
                                <CarTitleSpanWrap>
                                    <CarTitleSpan>{mainCar.title}</CarTitleSpan>
                                </CarTitleSpanWrap>
                                <CarPriceSpan>
                                    <CarPriceStatus2 item={mainCar} />
                                </CarPriceSpan>
                            </CarTitleWrap>
                        </CarWrap>
                        <CallWrap>
                            {/* 전화하기 */}
                            {isMobile ? (
                                <a href={`tel:${userPhone}`}>
                                    <CircleWrap>
                                        <Call width={'95%'} height={'95%'} />
                                    </CircleWrap>
                                </a>
                            ) : (
                                <CircleWrap>
                                    <Call width={'95%'} height={'95%'} />
                                </CircleWrap>
                            )}
                        </CallWrap>
                    </BottomWrap>
                </BottomAllWrap>
            </div>
        </div>
    );
}

export default FirstDetailScreen;

// 상단
const TopAllWrap = styled.div`
    top: 0;
    width: 100%;
    background: linear-gradient(rgba(20, 20, 20, 1), rgba(52, 52, 52, 0.1));
    position: absolute;
    padding: 13px 0;
    animation: fadein 0.6s linear forwards;
    @media (max-width: 430px) {
    }
    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const TopWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ProfileImgWrap = styled.div`
    width: 85px;
    height: 85px;
    position: relative;
    padding: 10px 10px;
    @media (max-width: 430px) {
        width: 75px;
        height: 75px;
    }
`;

const ProfileImg = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    overflow: hidden;
`;

const VodStatusWrap = styled.div`
    position: absolute;
    bottom: 9px;
    right: 9px;
    padding: 2px 7px;
    background-color: #a42bf5;
    border-radius: 10px;
    color: #ffffff;
    font-size: 12px;
    @media (max-width: 430px) {
        padding: 2px 5px;
        font-size: 10px;
        bottom: 9px;
        right: 9px;
    }
`;

const TitleWrap = styled.div`
    /* 이미지 닫기버튼 영역 고려 */
    width: calc(100% - 112px);
    overflow: hidden;
    @media (max-width: 430px) {
        width: calc(100% - 98px);
    }
`;

const TitleSpan = styled.div.attrs((props: { isMore: boolean }) => props)`
    font-size: 20px;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.isMore ? '3' : '2')};
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: bold;
    cursor: pointer;
    @media (max-width: 430px) {
        font-size: 16px;
    }
`;

const HitCountWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.7em;
    @media (max-width: 430px) {
        margin-top: 0.5em;
    }
`;

const NickSpan = styled.div`
    color: #ffffff;
    font-size: 16px;
    @media (max-width: 430px) {
        font-size: 14px;
    }
`;

const HitCountImgWrap = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 1.3em;
    margin-right: 0.3em;
    @media (max-width: 430px) {
        width: 16px;
        height: 16px;
    }
`;

const HitCountSpan = styled.span`
    color: gray;
    font-size: 16px;
    @media (max-width: 430px) {
        font-size: 14px;
    }
`;

const CloseImgWrap = styled.div`
    width: 37px;
    height: 37px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    @media (max-width: 430px) {
        width: 33px;
        height: 33px;
    }
`;

// 하단
const BottomAllWrap = styled.div`
    bottom: 0;
    width: 100%;
    position: absolute;
    padding: 10px 10px;
    animation: fadein 0.6s linear forwards;
    @media (max-width: 430px) {
    }
    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const BottomWrap = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    justify-content: space-between;
    @media (max-width: 430px) {
        height: 95px;
    }
`;

const CarWrap = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    @media (max-width: 430px) {
    }
`;

const CarImgWrap = styled.div`
    width: 180px;
    height: 100%;
    @media (max-width: 430px) {
        width: 160px;
    }
`;

const CarTitleWrap = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #ffffff;
    @media (max-width: 430px) {
    }
`;

const CarTitleSpanWrap = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
`;

const CarTitleSpan = styled.div`
    color: #000000;
    font-weight: bold;
    font-size: 1.15em;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: 430px) {
        font-size: 0.93em;
    }
`;

const CarPriceSpan = styled.div`
    font-size: 1.15em;
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 0.93em;
    }
`;

const CallWrap = styled.div`
    height: '100%';
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 430px) {
    }
`;

const CircleWrap = styled.div`
    width: 62px;
    height: 62px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    @media (max-width: 430px) {
        width: 59px;
        height: 59px;
    }
`;
