import React from 'react';
import styled from 'styled-components';

type MeetchaDetailPageLayoutProps = {
    children: React.ReactNode;
};

function MeetchaDetailPageLayout({ children }: MeetchaDetailPageLayoutProps) {
    return (
        <Wrapper>
            <FirstDiv>
                <SecondDiv>{children}</SecondDiv>
            </FirstDiv>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.bgColor.primary};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FirstDiv = styled.div`
    background-color: ${(props) => props.theme.bgColor.secondary};
    width: 100%;
`;

const SecondDiv = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    max-width: 600px;
    margin: 0 auto;
`;

export default MeetchaDetailPageLayout;
