import { useState } from 'react';
import styled from 'styled-components';
import defaultCarImage from '../../../assets/images/defaultImage.jpg';
import { ReactComponent as Eye } from '../../../assets/images/eye_thin.svg';
import { ReactComponent as Polygon } from '../../../assets/images/polygon.svg';
import { ItemCarPriceStatus, NumberCommaFormat, timeForYYMM } from '../../common/Util';
import { getThemeState, useMclid } from '../../../context/Providers';
import { useNavigate } from 'react-router-dom';

type BroadcastListItemProps = {
    listItem: any;
    broadItemClick: () => void;
};

function BroadcastListItem({ listItem, broadItemClick }: BroadcastListItemProps) {
    const navigate = useNavigate();
    const themeMode = getThemeState();
    const [mclid, settingMclid] = useMclid();
    const [imageErrorChack, setImageErrorChack] = useState<boolean>(false);
    const title = listItem.title;
    const carData = listItem.carList[0];
    const hitCount = listItem.hitCount;
    const number = listItem.carList[0].number;
    const seq = String(listItem.seq);
    const liveType = listItem.liveType;
    const wdtbSttus = listItem.wdtbSttus;

    function handleImgOnError(e: any) {
        setImageErrorChack(true);
        e.target.src = defaultCarImage;
    }

    function goCarDetail() {
        navigate(`/car/detail?mclid=${mclid}&theme=${themeMode}&number=${String(number)}`);
    }

    function goBroadDetail() {
        navigate(`/broadcast/detail?mclid=${mclid}&seq=${seq}&liveType=${liveType}&wdtbSttus=${wdtbSttus}`);
    }

    function parentClickPrevent(e: any) {
        e.stopPropagation();
    }

    return (
        // <a
        //     href={`/car/detail?theme=${themeMode === 'light' ? 'light' : 'dark'}&number=${String(number)}`}
        //     target={'_blank'}
        // >
        // <Link
        //     onClick={broadItemClick}
        //     to={`/car/detail?theme=${themeMode === 'light' ? 'light' : 'dark'}&number=${String(number)}`}
        // >
        <BroadcastListItemWrap onClick={goCarDetail}>
            <BroadcastItemImageWrap>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        borderRadius: 15,
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                >
                    <LinearGradientBottomWrap backgroundVisible={!imageErrorChack} />
                    <img
                        src={carData.img1.includes('d2rxlfguhx92xl') ? `${carData.img1}&w=500` : carData.img1}
                        width={'100%'}
                        height={'100%'}
                        onError={handleImgOnError}
                    />
                </div>

                <StatusAndHitcountWrap>
                    <StatusWrap>
                        <VodSpan>VOD</VodSpan>
                    </StatusWrap>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <EyeWrap>
                            <Eye width={'100%'} height={'100%'} fill={'#FFFFFF'} />
                        </EyeWrap>
                        <HitcountSpan>{hitCount}</HitcountSpan>
                    </div>
                </StatusAndHitcountWrap>
                {/* <Link to={`/broadcast/detail?seq=${seq}&liveType=${liveType}&wdtbSttus=${wdtbSttus}`}> */}
                <PlayWrap onClick={parentClickPrevent}>
                    <PlayInWrap onClick={goBroadDetail}>
                        <PolygonWrap>
                            <Polygon width={'100%'} height={'100%'} />
                        </PolygonWrap>
                    </PlayInWrap>
                </PlayWrap>
                {/* </Link> */}
            </BroadcastItemImageWrap>
            <div style={{ width: '100%', position: 'relative' }}>
                <TitleWrap>
                    <TitleSpan>{title}</TitleSpan>
                </TitleWrap>
                <YmKmSi>
                    {timeForYYMM(carData.ym)}&nbsp;·&nbsp;{NumberCommaFormat(String(carData.mileage))} km&nbsp;·&nbsp;
                    {carData.siName}
                </YmKmSi>
                <PriceWarp>{<ItemCarPriceStatus item={carData} />}</PriceWarp>
            </div>
        </BroadcastListItemWrap>
        // </Link>
        // </a>
    );
}

export default BroadcastListItem;

const HitcountSpan = styled.span`
    height: 100%;
    vertical-align: middle;
    color: #ffffff;
    margin-left: 0.3em;
    font-size: 1em;
    font-family: 'Pretendard-ExtraLight';
    @media (max-width: 430px) {
        font-size: 0.7em;
    }
`;

const EyeWrap = styled.div`
    width: 20px;
    height: 25px;
    margin-left: 0.3em;
    @media (max-width: 430px) {
        width: 15px;
        height: 18px;
    }
`;

const VodSpan = styled.div`
    height: 100%;
    vertical-align: middle;
    color: #ffffff;
    font-size: 1em;
    /* font-weight: bold; */
    font-weight: 100;
    font-family: 'Pretendard-ExtraLight';
    @media (max-width: 430px) {
        font-size: 0.7em;
    }
`;

const StatusWrap = styled.div`
    border-radius: 20px;
    background-color: #a42bf5;
    display: flex;
    justify-content: center;
    padding: 3px 8px;
    @media (max-width: 430px) {
        padding: 2px 6px;
    }
`;

const StatusAndHitcountWrap = styled.div`
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    display: flex;
    padding-right: 10px;
    @media (max-width: 430px) {
    }
`;

const LinearGradientBottomWrap = styled.div.attrs((props: { backgroundVisible: boolean }) => props)`
    width: 100%;
    height: 18%;
    bottom: 0;
    right: 0;
    position: absolute;
    background: ${(props) =>
        props.backgroundVisible ? `linear-gradient(rgba(52, 52, 52, 0.1), rgba(20, 20, 20, 1))` : null};
`;

const BroadcastListItemWrap = styled.div`
    width: 100%;
    padding-bottom: 3em;
    background-color: ${(props) => props.theme.bgColor.primary};
    animation: fadein 0.2s linear forwards;
    cursor: pointer;
    @media (max-width: 430px) {
        padding-bottom: 2.8em;
    }
    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const BroadcastItemImageWrap = styled.div`
    position: relative;
    width: 100%;
    height: 380px;
    @media (max-width: 430px) {
        height: 300px;
    }
`;

const PlayWrap = styled.div`
    right: 20px;
    bottom: -40px;
    position: absolute;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    background-color: ${(props) => props.theme.bgColor.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 430px) {
        width: 70px;
        height: 70px;
        bottom: -35px;
    }
`;

const PlayInWrap = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background-color: #ff436c;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 430px) {
        width: 60px;
        height: 60px;
    }
`;

const PolygonWrap = styled.div`
    margin-left: 10%;
    width: 55%;
    height: 55%;
    @media (max-width: 430px) {
        width: 50%;
        height: 50%;
    }
`;

const TitleWrap = styled.div`
    margin-top: 1em;
    /* height: 4em; */
    width: calc(100% - 120px);
    @media (max-width: 430px) {
        width: calc(100% - 100px);
    }
`;

const TitleSpan = styled.div`
    width: 100%;
    font-size: 1.45em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.2em;
    }
`;

const YmKmSi = styled.div`
    margin-top: 0.8em;
    font-size: 1.1em;
    color: ${(props) => props.theme.text.primary};
    @media (max-width: 430px) {
        font-size: 0.95em;
    }
`;

const PriceWarp = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;
