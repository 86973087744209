import styled from 'styled-components';
import Slider from '@mui/material/Slider';
import { forwardRef, Fragment, SetStateAction } from 'react';
import { ReactComponent as SpeakerOff } from '../../../assets/images/speakeroff.svg';
import { ReactComponent as SpeakerOn } from '../../../assets/images/speakeron.svg';
import { ReactComponent as X } from '../../../assets/images/x_origin.svg';
import { ReactComponent as Pause } from '../../../assets/images/flat-pause3.svg';
import { ReactComponent as Play } from '../../../assets/images/play.svg';
import { useNavigate } from 'react-router-dom';

type VideoControlProps = {
    originData: any;
    onSeek: any;
    onSeekMouseDown: any;
    onSeekMouseUp: any;
    onDuration: any;
    onPlayPause: any;
    playing: any;
    played: any;
    elapsedTime: any;
    totalDuration: any;
    onMute: any;
    muted: any;
    onVolumeChange: any;
    onVolumeSeekDown: any;
    playbackRate: any;
    onPlaybackRateChange: any;
    onToggleFullScreen: any;
    volume: any;
    screenChangeToggle: boolean;
    setScreenChangeToggle: (value: SetStateAction<boolean>) => void;
};

const VideoControl = forwardRef((props: VideoControlProps, ref: any) => {
    const {
        originData,
        onSeek,
        onSeekMouseDown,
        onSeekMouseUp,
        onDuration,
        onPlayPause,
        playing,
        played,
        elapsedTime,
        totalDuration,
        onMute,
        muted,
        onVolumeChange,
        onVolumeSeekDown,
        playbackRate,
        onPlaybackRateChange,
        onToggleFullScreen,
        volume,
        screenChangeToggle,
        setScreenChangeToggle,
    } = props;
    const navigate = useNavigate();
    const liveType = originData.liveType;

    function closeClick() {
        window.close();
        navigate(-1);
    }

    function parentClickPrevent(e: any) {
        e.stopPropagation();
    }

    function onScreenChange() {
        setScreenChangeToggle(!screenChangeToggle);
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            onClick={() => {
                setScreenChangeToggle((prev) => !prev);
            }}
        >
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <TopAllWrap onClick={parentClickPrevent}>
                    <TopWrap>
                        <MuteImageWrap onClick={onMute}>
                            {muted ? (
                                <SpeakerOff width={'100%'} height={'100%'} />
                            ) : (
                                <SpeakerOn width={'100%'} height={'100%'} />
                            )}
                        </MuteImageWrap>
                        <CloseImgWrap onClick={closeClick}>
                            <X width={'100%'} height={'100%'} />
                        </CloseImgWrap>
                    </TopWrap>
                </TopAllWrap>
                <BottomAllWrap onClick={parentClickPrevent}>
                    <PalyPauseImageWrap onClick={onPlayPause}>
                        {playing ? <Pause width={'100%'} height={'100%'} /> : <Play width={'100%'} height={'100%'} />}
                    </PalyPauseImageWrap>
                    <TimeWrap>{elapsedTime}</TimeWrap>
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={played * 100}
                            step={1}
                            min={0}
                            max={100}
                            onChange={onSeek}
                            sx={{
                                color: '#fff',
                                height: 5,
                                '& .MuiSlider-thumb': {
                                    width: 17,
                                    height: 17,
                                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                    '&:before': {
                                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                    },
                                    '&.Mui-active': {
                                        width: 20,
                                        height: 20,
                                    },
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.28,
                                },
                            }}
                        />
                    </div>
                    <TimeWrap>{totalDuration}</TimeWrap>
                </BottomAllWrap>
            </div>
        </div>
    );
});

export default VideoControl;

const TopAllWrap = styled.div`
    top: 0;
    width: 100%;
    position: absolute;
    padding-top: 13.5px;
    padding-bottom: 13px;
    padding-left: 10px;
    padding-right: 5px;
`;

const TopWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const MuteImageWrap = styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    @media (max-width: 430px) {
        width: 28px;
        height: 28px;
    }
`;

const CloseImgWrap = styled.div`
    width: 36px;
    height: 36px;
    cursor: pointer;
    @media (max-width: 430px) {
        width: 31px;
        height: 31px;
    }
`;

const BottomAllWrap = styled.div`
    bottom: 0;
    width: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    padding: 10px 8px;
`;

const PalyPauseImageWrap = styled.div`
    height: 40px;
    width: 40px;
    cursor: pointer;
    @media (max-width: 430px) {
        width: 38px;
        height: 38px;
    }
`;

const TimeWrap = styled.div`
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 1.2em;
    width: 80px;
    @media (max-width: 430px) {
        font-size: 1.1em;
    }
`;

// const VideoControlAllWrap = styled.div`
//     width: 100%;
//     position: absolute;
//     top: 0;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding-top: 23px;
//     padding-bottom: 13px;
//     padding-left: 15px;
//     padding-right: 15px;
//     @media (max-width: 430px) {
//         padding-left: 10px;
//         padding-right: 10px;
//     }
// `;

// const SpeakerImageWrap = styled.div`
//     position: relative;
//     width: 32px;
//     height: 32px;
//     cursor: pointer;
//     @media (max-width: 430px) {
//         width: 27px;
//         height: 27px;
//     }
// `;

// const SecondScreenCloseWrap = styled.div`
//     background-color: red;
//     position: relative;
//     padding-left: 10px;
//     padding-right: 15px;
//     width: 40px;
//     height: 40px;
//     cursor: pointer;
//     @media (max-width: 430px) {
//         width: 30px;
//         height: 30px;
//     }
// `;

// const BottomVideoControlWrap = styled.div`
//     width: 100%;
//     position: absolute;
//     bottom: 0;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 13px 15px;
//     @media (max-width: 430px) {
//         padding: 13px 10px;
//     }
// `;

// const PalyPauseImageWrap = styled.div`
//     height: 40px;
//     width: 40px;
//     cursor: pointer;
//     position: relative;
//     @media (max-width: 430px) {
//         width: 38px;
//         height: 38px;
//     }
// `;

// const CurrentTimeWrap = styled.div`
//     padding: 0 15px;
//     @media (max-width: 430px) {
//         padding: 0 10px;
//     }
// `;

// const TimeSpan = styled.span`
//     color: #ffffff;
//     font-weight: bold;
//     font-size: 1.2em;
//     @media (max-width: 430px) {
//         font-size: 1em;
//     }
// `;

// const TotalTimeWrap = styled.div`
//     padding-left: 15px;
//     @media (max-width: 430px) {
//         padding-left: 10px;
//     }
// `;
