import Modal from 'react-modal';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { SetStateAction, useState } from 'react';
import { ReactComponent as CheckOff } from '../../assets/images/checkOff.svg';
import { ReactComponent as CheckOn } from '../../assets/images/checkOn.svg';
import { useTheme } from '../../context/Providers';
import { useEffect } from 'react';
type CarDetailOptionModalProps = {
    carOptins: any;
    isVisible: boolean;
    setIsVisible: (value: SetStateAction<boolean>) => void;
};

interface CarOptionVO {
    title: string;
    optionType: string;
}

function CarDetailOptionModal({ carOptins, isVisible, setIsVisible }: CarDetailOptionModalProps) {
    const [optionTitle, setOptionTitle] = useState<string>('외관');
    const [themeMode, toggleTheme, fixedThemeMode] = useTheme();

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const optionTitleArr: CarOptionVO[] = [
        { title: '외관', optionType: 'car_arr_option4' },
        { title: '내장', optionType: 'car_arr_option3' },
        { title: '안전', optionType: 'car_arr_option5' },
        { title: '편의', optionType: 'car_arr_option2' },
        { title: '기타', optionType: 'car_arr_option8' },
    ];

    function onRequestClose() {
        if (isVisible) document.body.style.overflow = 'unset';
        setIsVisible(false);
    }

    function onClick(optTitle: string) {
        setOptionTitle(optTitle);
    }

    function customStyles() {
        if (isMobile) {
            if (themeMode === 'light') {
                return {
                    content: {
                        borderRadius: 10,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: 400,
                    },
                    overlay: {
                        zIndex: 1002,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                };
            } else {
                return {
                    content: {
                        borderRadius: 10,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: 400,
                    },
                    overlay: {
                        zIndex: 1002,
                    },
                };
            }
        } else {
            if (themeMode === 'light') {
                return {
                    content: {
                        borderRadius: 10,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: 500,
                    },
                    overlay: {
                        zIndex: 1002,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                };
            } else {
                return {
                    content: {
                        borderRadius: 10,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: 500,
                    },
                    overlay: {
                        zIndex: 1002,
                    },
                };
            }
        }
    }

    return (
        <Modal isOpen={isVisible} ariaHideApp={false} onRequestClose={onRequestClose} style={customStyles()}>
            <div style={{ width: '100%' }}>
                <div style={{ height: 120 }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '0.3em' }}>
                        <span style={{ fontSize: '1em', fontWeight: 'bold' }}>세부옵션</span>
                    </div>
                    <ul
                        style={{
                            listStyle: 'none',
                            whiteSpace: 'nowrap',
                            margin: 0,
                            padding: 0,
                            marginTop: '1.5em',
                        }}
                    >
                        {optionTitleArr.map((item: CarOptionVO) => {
                            return (
                                <AtiveWrap
                                    key={item.title}
                                    onClick={() => onClick(item.title)}
                                    color={optionTitle === item.title ? '#ff436c' : '#6f6f6f'}
                                >
                                    {item.title}
                                </AtiveWrap>
                            );
                        })}
                    </ul>
                </div>
                <OptionInfoWrap>
                    {carOptins[
                        optionTitleArr[
                            optionTitleArr.findIndex((element: CarOptionVO) => element.title === optionTitle)
                        ].optionType
                    ].map((item: any) => {
                        return (
                            <div
                                key={item.title}
                                style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}
                            >
                                {item.option_chk === '0' ? <CheckOff /> : <CheckOn />}
                                <OptionContentSpan color={item.option_chk === '0' ? '#6F6F6F' : '#FF436C'}>
                                    {item.title}
                                </OptionContentSpan>
                            </div>
                        );
                    })}
                </OptionInfoWrap>
                <div
                    style={{
                        marginTop: '1em',
                        width: '100%',
                        height: 35,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <span
                            style={{ color: 'black', fontSize: '0.95em', fontWeight: 'bold' }}
                            onClick={onRequestClose}
                        >
                            닫기
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const AtiveWrap = styled.li.attrs((props: { color: string }) => props)`
    display: inline-block;
    width: 20%;
    text-align: center;
    padding: 15px 0;
    border-bottom-width: 2px;
    border-bottom-color: ${(props) => props.color};
    border-bottom-style: solid;
    font-size: 0.9em;
    color: ${(props) => props.color};
    cursor: pointer;
`;

const OptionInfoWrap = styled.div`
    width: '100%';
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        height: 20%;
        background: gray;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1);
    }
`;

const OptionContentSpan = styled.span.attrs((props: { color: string }) => props)`
    margin-left: 0.8em;
    font-size: 0.9em;
    color: ${(props) => props.color};
`;

export default CarDetailOptionModal;
