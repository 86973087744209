import { Fragment } from 'react';
import styled from 'styled-components';
import { NumberCommaFormat } from '../../common/Util';
import { ReactComponent as Medal } from '../../../assets/images/best_price.svg';

type CarPriceCompareListItemProps = {
    carCompareData: any;
};

function CarPriceCompareListItem({ carCompareData }: CarPriceCompareListItemProps) {
    const bestPrice = carCompareData.bestPrice;
    const carPriceCompareList = carCompareData.priceCompareList;
    return (
        <Fragment>
            {carPriceCompareList.map((item: any) => {
                return String(item.price) === String(bestPrice) ? (
                    <div
                        key={item.siteNm}
                        style={{
                            width: '100%',
                            height: 65,
                            borderWidth: 2,
                            borderColor: '#ff436c',
                            borderStyle: 'solid',
                            marginRight: 5,
                        }}
                    >
                        <div
                            style={{
                                height: '50%',
                                backgroundColor: '#ff436c',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ActiveCompanySpan>{item.siteNm}사</ActiveCompanySpan>
                            <Medal />
                        </div>
                        <ComparePriceSpanWrap>
                            <PriceSpan>{NumberCommaFormat(item.price)}만원</PriceSpan>
                        </ComparePriceSpanWrap>
                    </div>
                ) : (
                    <div
                        key={item.siteNm}
                        style={{
                            width: '100%',
                            height: 65,
                            borderWidth: 2,
                            borderColor: '#6f6f6f',
                            borderStyle: 'solid',
                            marginRight: 5,
                        }}
                    >
                        <div
                            style={{
                                height: '50%',
                                backgroundColor: '#6f6f6f',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <DisableCompanySpan>{item.siteNm}사</DisableCompanySpan>
                        </div>
                        <ComparePriceSpanWrap>
                            <PriceSpan>{NumberCommaFormat(item.price)}만원</PriceSpan>
                        </ComparePriceSpanWrap>
                    </div>
                );
            })}
        </Fragment>
    );
}

const ActiveCompanySpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    margin-right: 0.2em;
    font-size: 1em;
    /* font-weight: bold; */
`;
const DisableCompanySpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1em;
    /* font-weight: bold; */
`;

const ComparePriceSpanWrap = styled.div`
    height: 50%;
    background-color: ${(props) => props.theme.bgColor.primary};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PriceSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1em;
    /* font-weight: bold; */
`;

export default CarPriceCompareListItem;
