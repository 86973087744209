import { Fragment } from 'react';
import styled from 'styled-components';

type CarDetailInfoProps = {
    originData: any;
};

function CarDetailInfo({ originData }: CarDetailInfoProps) {
    const carSaleType = originData.result.data[0].sale_type;
    const carSaleEnd = originData.result.data[0].sale_end;
    const carData = originData.result.data[0];
    const carDetailInfoArr: any = [
        { carInfoKind: '차량번호', value: carData.car_number },
        { carInfoKind: '주행거리', value: `${carData.mileage}km` },
        { carInfoKind: '연료', value: carData.fuel },
        { carInfoKind: '차종', value: carData.car_model_class },
        { carInfoKind: '지역', value: carData.si },
        { carInfoKind: '연식', value: carData.ym },
        { carInfoKind: '배기량', value: `${carData.gas}cc` },
        { carInfoKind: '변속기', value: carData.atmt },
        { carInfoKind: '색상', value: carData.tone },
        { carInfoKind: '사고유무', value: carData.sago },
    ];

    return (
        <div style={{ width: '100%', height: '100%', marginTop: '4em' }}>
            <div
                style={{
                    width: '100%',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottomWidth: 2,
                    borderColor: '#ff436c',
                    borderBottomStyle: 'solid',
                }}
            >
                <Title>차량정보</Title>
            </div>
            <div style={{ width: '100%', marginTop: '1.3em', marginBottom: '1.8em' }}>
                {carSaleType === '1'
                    ? carDetailInfoArr.map((item: any) => {
                          return (
                              <div
                                  key={item.carInfoKind}
                                  style={{
                                      marginTop: '0.5em',
                                      display: 'flex',
                                      width: '100%',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                  }}
                              >
                                  <CarInfoKindSpan>{item.carInfoKind}</CarInfoKindSpan>
                                  <CarInfoValueSapn>{item.value}</CarInfoValueSapn>
                              </div>
                          );
                      })
                    : carDetailInfoArr
                          .filter((item: any) => item.carInfoKind !== '주행거리' && item.carInfoKind !== '사고유무')
                          .map((item: any) => {
                              return (
                                  <div
                                      key={item.carInfoKind}
                                      style={{
                                          marginTop: '0.5em',
                                          display: 'flex',
                                          width: '100%',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                      }}
                                  >
                                      <CarInfoKindSpan>{item.carInfoKind}</CarInfoKindSpan>
                                      <CarInfoValueSapn>{item.value}</CarInfoValueSapn>
                                  </div>
                              );
                          })}
            </div>

            <div style={{ marginTop: '2em' }}></div>

            {/* 성능점검기록부 */}
            {carSaleType === '1' ? (
                <PrfomncChckWrap
                    bol={carData.sale_end === '0'}
                    onClick={
                        carData.sale_end === '0'
                            ? () =>
                                  window.open(
                                      carData.prfomnc_chck_url,
                                      `${String(carData.car_number)}`,
                                      'width=900,height=1200',
                                  )
                            : () => alert('판매 완료된 차량은 성능점검기록부를 조회할 수 없습니다.')
                    }
                >
                    <PrfomncChckSpan>성능점검기록부</PrfomncChckSpan>
                </PrfomncChckWrap>
            ) : null}
        </div>
    );
}

const CarInfoKindSpan = styled.span`
    color: ${(props) => (props.theme.state === 'light' ? '#8d8c8c' : '#6f6f6f')};
    font-size: 1em;
    font-weight: bold;
`;

const CarInfoValueSapn = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1em;
    font-weight: bold;
`;

const PrfomncChckWrap = styled.div.attrs((props: { bol: boolean }) => props)`
    width: 95%;
    height: 30px;
    background-color: ${(props) => (props.bol ? '#ff436c' : '#8f8e8e')};
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.bol ? 'pointer' : null)};
`;

const PrfomncChckSpan = styled.span`
    color: #ffffff;
    font-size: 0.9em;
    /* font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)}; */
`;

const Title = styled.span`
    color: #ff436c;
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

export default CarDetailInfo;
