import { Fragment } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

type CarInquireProps = {
    originData: any;
};

function CarInquire({ originData }: CarInquireProps) {
    const saleType = originData.result.data[0].sale_type;
    const userPhone = originData.result.seller.user_hphone;
    const saleEnd = originData.result.data[0].sale_end;
    return (
        <Fragment>
            {isMobile ? (
                saleEnd === '0' ? (
                    <a href={`tel:${userPhone}`}>
                        <InquireWrap saleEnd={saleEnd}>
                            {saleType === '5' ? '구독문의' : saleType === '3' ? '렌트문의' : '구매문의'}
                        </InquireWrap>
                    </a>
                ) : (
                    <InquireWrap saleEnd={saleEnd}>판매완료</InquireWrap>
                )
            ) : saleEnd === '0' ? (
                <InquireWrap saleEnd={saleEnd}>
                    {saleType === '5' ? '구독문의' : saleType === '3' ? '렌트문의' : '구매문의'}
                </InquireWrap>
            ) : (
                <InquireWrap saleEnd={saleEnd}>판매완료</InquireWrap>
            )}
        </Fragment>
    );
}

const InquireWrap = styled.div.attrs((props: { saleEnd: string }) => props)`
    overflow: hidden;
    border-radius: 7px;
    width: 100%;
    background-color: ${(props) => (props.saleEnd === '0' ? '#a42bf5' : '#8f8e8e')};
    height: 50px;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1em;
    bottom: 0;
    font-weight: bold;
    cursor: pointer;
    z-index: 5;
`;

export default CarInquire;
