import { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import screenful from 'screenfull';
import ReactPlayer from 'react-player';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { meetchaBroadcastDetailAPI } from '../../api/MeetchaAPI';
import VideoControl from '../../components/meetcha/broadcastdetail/VideoControl';
import MeetchaDetailPageLayout from '../../components/layout/MeetchaDetailPageLayout';
import FirstDetailScreen from '../../components/meetcha/broadcastdetail/FirstDetailScreen';
import CarListModal from '../../components/modal/CarListModal';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

async function fetcher(url: string) {
    const res = await axios.get(url);
    return res.data;
}

function BraodcastDetail() {
    const navigate = useNavigate();
    const [screenChangeToggle, setScreenChangeToggle] = useState<boolean>(true);
    const [moreTitle, setMoreTitle] = useState<boolean>(false);
    const [carListModalVisible, setCarListModalVisible] = useState<boolean>(false);

    // 데이터
    const location = useLocation();
    const query = queryString.parse(location.search);
    const { data, error } = useSWR(
        `${meetchaBroadcastDetailAPI}?seq=${query.seq}&liveType=${query.liveType}&wdtbSttus=${query.wdtbSttus}&hit=Y`,
        fetcher,
    );

    useEffect(() => {
        if (error) navigate('/error/404');
    }, [error]);

    // 비디오 설정
    const [state, setState] = useState({
        pip: false,
        playing: true,
        controls: false,
        light: false,
        muted: true,
        played: 0,
        duration: 0,
        playbackRate: 1.0,
        volume: 1,
        loop: false,
        seeking: false,
    });
    const { playing, controls, light, muted, loop, playbackRate, pip, played, seeking, volume } = state;
    const playerRef = useRef<any>(null);
    const playerContainerRef = useRef<any>(null);
    const controlsRef = useRef<any>(null);
    const [timeDisplayFormat, setTimeDisplayFormat] = useState('normal');

    function format(seconds: any) {
        if (isNaN(seconds)) return `00:00`;
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, '0');
        if (hh) return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
        return `${mm}:${ss}`;
    }

    const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
    const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00';
    const elapsedTime = timeDisplayFormat == 'normal' ? format(currentTime) : `-${format(duration - currentTime)}`;
    const totalDuration = format(duration);

    //  비디오 기능
    function handlePlayPause() {
        setState({ ...state, playing: !state.playing });
    }

    function handleProgress(changeState: any) {
        if (!state.seeking) {
            if (changeState.played === 1) {
                playerRef.current.seekTo(0);
                setState({ ...state, playing: false });
            } else {
                setState({ ...state, ...changeState });
            }
        }
    }

    function handleSeekChange(e: any, newValue: any) {
        playerRef.current.seekTo(newValue / 100);
        setState({ ...state, played: parseFloat(String(newValue / 100)) });
    }

    function handleSeekMouseDown(e: any) {
        setState({ ...state, seeking: true });
    }

    function handleSeekMouseUp(e: any, newValue: any) {
        setState({ ...state, seeking: false });
        playerRef.current.seekTo(newValue / 100, 'fraction');
    }

    function handleDuration(duration: any) {
        setState({ ...state, duration });
    }

    function handleVolumeSeekDown(e: any, newValue: any) {
        setState({ ...state, seeking: false, volume: parseFloat(String(newValue / 100)) });
    }

    function handleVolumeChange(e: any, newValue: any) {
        setState({
            ...state,
            volume: parseFloat(String(newValue / 100)),
            muted: newValue === 0 ? true : false,
        });
    }

    function handleFullScreen() {
        screenful.toggle(playerContainerRef.current);
    }

    function handlePlaybackRate(rate: any) {
        setState({ ...state, playbackRate: rate });
    }

    function hanldeMute() {
        setState({ ...state, muted: !state.muted });
    }

    useEffect(() => {
        // setTimeout(() => {
        //     setState({ ...state, muted: false });
        // }, 4000);
    }, []);

    return (
        <Fragment>
            {data ? (
                <Fragment>
                    <MeetchaDetailPageLayout>
                        <div
                            style={{
                                width: '100%',
                                height: '100vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                            }}
                        >
                            <div style={{ width: '100%', maxWidth: 555, height: '100%', maxHeight: 990 }}>
                                <div
                                    ref={playerContainerRef}
                                    style={{ width: '100%', height: '100%', position: 'relative' }}
                                >
                                    <ReactPlayer
                                        ref={playerRef}
                                        width="100%"
                                        height="100%"
                                        url={data.cdnDomain + data.cdnRestUrl}
                                        pip={pip}
                                        playing={playing}
                                        controls={false}
                                        light={light}
                                        loop={loop}
                                        playbackRate={playbackRate}
                                        volume={volume}
                                        muted={muted}
                                        onProgress={handleProgress}
                                        config={{
                                            file: {
                                                attributes: {
                                                    playsInline: true,
                                                    crossOrigin: 'true',
                                                },
                                            },
                                        }}
                                    />
                                    {screenChangeToggle ? (
                                        <FirstDetailScreen
                                            originData={data}
                                            screenChangeToggle={screenChangeToggle}
                                            setScreenChangeToggle={setScreenChangeToggle}
                                            moreTitle={moreTitle}
                                            setMoreTitle={setMoreTitle}
                                            carListModalVisible={carListModalVisible}
                                            setCarListModalVisible={setCarListModalVisible}
                                        />
                                    ) : (
                                        <VideoControl
                                            ref={controlsRef}
                                            originData={data}
                                            onSeek={handleSeekChange}
                                            onSeekMouseDown={handleSeekMouseDown}
                                            onSeekMouseUp={handleSeekMouseUp}
                                            onDuration={handleDuration}
                                            onPlayPause={handlePlayPause}
                                            playing={playing}
                                            played={played}
                                            elapsedTime={elapsedTime}
                                            totalDuration={totalDuration}
                                            onMute={hanldeMute}
                                            muted={muted}
                                            onVolumeChange={handleVolumeChange}
                                            onVolumeSeekDown={handleVolumeSeekDown}
                                            playbackRate={playbackRate}
                                            onPlaybackRateChange={handlePlaybackRate}
                                            onToggleFullScreen={handleFullScreen}
                                            volume={volume}
                                            screenChangeToggle={screenChangeToggle}
                                            setScreenChangeToggle={setScreenChangeToggle}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </MeetchaDetailPageLayout>
                    <CarListModal
                        originData={data}
                        carListModalVisible={carListModalVisible}
                        setCarListModalVisible={setCarListModalVisible}
                    />
                </Fragment>
            ) : (
                <EalryWrap />
            )}
        </Fragment>
    );
}

const EalryWrap = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #000000;
`;

export default BraodcastDetail;
