import { createContext, useState, useContext, useCallback } from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';

type ProvidersProps = {
    children: React.ReactNode;
};

type GlobalContextType = {
    themeMode: string;
    setThemeMode: React.Dispatch<React.SetStateAction<string>>;
    mclid: string;
    setMclid: React.Dispatch<React.SetStateAction<string>>;
};

const light = {
    state: 'light',
    bgColor: {
        primary: '#FFFFFF',
        secondary: '#e5e9e9',
    },
    text: {
        primary: '#000000',
        secondary: '#000000',
    },
    borderColor: {
        primary: '#000000',
    },
    inputBg: {
        primary: '#cecbcb',
    },
};

const dark = {
    state: 'dark',
    bgColor: {
        primary: '#000000',
        secondary: '#000000',
    },
    text: {
        primary: '#FFFFFF',
        secondary: '#e5e9e9',
    },
    borderColor: {
        primary: '#FFFFFF',
    },
    inputBg: {
        primary: '#424242',
    },
};

const defaultTheme = {
    fontSizes: ['14px', '16px', '18px', '22px', '26px', '32px', '40px'],
    fontWeights: {
        body: 400,
        subheading: 500,
        link: 600,
        bold: 700,
        heading: 800,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.3,
        code: 1.6,
    },
};

const lightTheme = { ...defaultTheme, ...light };
const darkTheme = { ...defaultTheme, ...dark };

const GlobalContext = createContext<GlobalContextType | null>(null);

function Providers({ children }: ProvidersProps) {
    const [themeMode, setThemeMode] = useState('dark');
    const [mclid, setMclid] = useState('');
    const themeObject = themeMode === 'light' ? lightTheme : darkTheme;
    return (
        <GlobalContext.Provider value={{ themeMode, setThemeMode, mclid, setMclid }}>
            <StyledProvider theme={themeObject}>{children}</StyledProvider>
        </GlobalContext.Provider>
    );
}

function getThemeState(): string {
    const context = useContext(GlobalContext);
    const { themeMode } = context!;
    return themeMode;
}

function useTheme(): [string, () => void, (mode: string) => void] {
    const context = useContext(GlobalContext);
    const { themeMode, setThemeMode } = context!;
    const toggleTheme = useCallback(() => {
        if (themeMode === 'light') {
            setThemeMode('dark');
        } else {
            setThemeMode('light');
        }
    }, [themeMode]);
    const fixedThemeMode = (mode: string) => {
        setThemeMode(mode);
    };
    return [themeMode, toggleTheme, fixedThemeMode];
}

function useMclid(): [string, (gclidVal: string) => void] {
    const context = useContext(GlobalContext);
    const { mclid, setMclid } = context!;
    const settingMclid = (mclidVal: string) => {
        setMclid(mclidVal);
    };
    return [mclid, settingMclid];
}

export { Providers, getThemeState, useTheme, useMclid };
