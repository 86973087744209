import Modal from 'react-modal';
import { Fragment, useEffect } from 'react';
import { SetStateAction } from 'react';
import styled from 'styled-components';
import { CarPriceStatus3, NumberCommaFormat, timeForYYMM } from '../common/Util';
import { ReactComponent as X } from '../../assets/images/x_gray.svg';
import { getThemeState, useMclid, useTheme } from '../../context/Providers';
import { Link, useNavigate } from 'react-router-dom';
import defaultCarImage from '../../assets/images/defaultImage.jpg';

type CarListModalProps = {
    originData: any;
    carListModalVisible: boolean;
    setCarListModalVisible: (value: SetStateAction<boolean>) => void;
};

function CarListModal({ originData, carListModalVisible, setCarListModalVisible }: CarListModalProps) {
    const carList = originData.carList;
    const themeMode = getThemeState();
    const [mclid, settingMclid] = useMclid();

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    function onRequestClose() {
        if (carListModalVisible) document.body.style.overflow = 'unset';
        setCarListModalVisible(false);
    }

    function goCarDetail(number: string) {
        window.open(`/car/detail?theme=${themeMode === 'light' ? 'light' : 'dark'}&number=${String(number)}`);
    }

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    return (
        <Modal
            isOpen={carListModalVisible}
            ariaHideApp={false}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    borderRadius: 10,
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: 510,
                    maxWidth: 400,
                    paddingBottom: 20,
                    paddingTop: 20,
                    paddingRight: 20,
                    paddingLeft: 20,
                },
                overlay: {
                    zIndex: 1003,
                },
            }}
        >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <ModalTitleWrap>방송차량</ModalTitleWrap>
                <CloseImgWrap onClick={onRequestClose}>
                    <X width={'100%'} height={'100%'} />
                </CloseImgWrap>
            </div>

            <CarListWrap>
                {carList.map((list: any) => {
                    return (
                        <Link
                            key={list.number}
                            to={`/car/detail?mclid=${mclid}&theme=${themeMode}&number=${String(list.number)}`}
                        >
                            <CarListItemWrap key={list.number}>
                                <CarImgWrap>
                                    <img
                                        src={list.img1.includes('d2rxlfguhx92xl') ? `${list.img1}&w=200` : list.img1}
                                        width={'100%'}
                                        height={'100%'}
                                        onError={handleImgOnError}
                                    />
                                </CarImgWrap>
                                <CarInfoWrap>
                                    <CarTitleWrap>
                                        <CarTitleSpan>{list.title}</CarTitleSpan>
                                    </CarTitleWrap>
                                    <YyKmSiSpan>
                                        {timeForYYMM(list.ym)}&nbsp;&nbsp;·&nbsp;&nbsp;
                                        {NumberCommaFormat(String(list.mileage))}
                                        km&nbsp;&nbsp;·&nbsp;&nbsp;
                                        {list.siName}
                                    </YyKmSiSpan>
                                    <PriceSpan>
                                        <CarPriceStatus3 item={list} />
                                    </PriceSpan>
                                </CarInfoWrap>
                            </CarListItemWrap>
                        </Link>
                    );
                })}
            </CarListWrap>
        </Modal>
    );
}

const ModalTitleWrap = styled.div`
    color: #000000;
    font-size: 1.2em;
    font-weight: bold;
    @media (max-width: 430px) {
    }
`;

const CloseImgWrap = styled.div`
    width: 29px;
    height: 29px;
    cursor: pointer;
    @media (max-width: 430px) {
    }
`;

const CarListWrap = styled.div`
    width: '100%';
    height: 420px;
    margin-top: 0.9em;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        height: 20%;
        background: #afafaf;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1);
    }
`;

const CarListItemWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 110px;
    margin-bottom: 1em;
    cursor: pointer;
    @media (max-width: 430px) {
    }
`;

const CarImgWrap = styled.div`
    width: 230px;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 0.5em;
    @media (max-width: 430px) {
    }
`;

const CarInfoWrap = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 430px) {
    }
`;

const CarTitleWrap = styled.div`
    width: 100%;
    height: 48%;
    display: flex;
    align-items: center;
    @media (max-width: 430px) {
    }
`;

const CarTitleSpan = styled.div`
    color: #000000;
    font-weight: bold;
    font-size: 1em;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: 430px) {
    }
`;

const YyKmSiSpan = styled.div`
    color: rgba(111, 111, 111, 1);
    font-size: 0.8em;
    margin-top: 0.5em;
`;

const PriceSpan = styled.div`
    margin-top: 0.6em;
`;

export default CarListModal;
