import useSWR from 'swr';
import axios from 'axios';
import styled from 'styled-components';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useRef, Fragment } from 'react';
import { meetchaCarDetailAPI } from '../../api/MeetchaAPI';
import MeetchaDetailPageLayout from '../../components/layout/MeetchaDetailPageLayout';
import { checkIntersecting } from '../../customhooks/CustomHooks';
import HiddenCarDetailInfoNavBar from '../../components/meetcha/cardetail/HiddenCarDetailInfoNavBar';
import GudokComment from '../../components/meetcha/cardetail/GudokComment';
import CarImageSlider from '../../components/meetcha/cardetail/CarImageSlider';
import CarPriceCompare from '../../components/meetcha/cardetail/CarPriceCompare';
import CarDetailInfo from '../../components/meetcha/cardetail/CarDetailInfo';
import CarDetailOption from '../../components/meetcha/cardetail/CarDetailOption';
import CarDealerInfo from '../../components/meetcha/cardetail/CarDealerInfo';
import { getThemeState } from '../../context/Providers';
import { useEffect } from 'react';
import DealerCars from '../../components/meetcha/cardetail/DealerCars';
import CarRelationBroacastList from '../../components/meetcha/cardetail/CarRelationBroacastList';
import ChalyangGuMaeBangBeob from '../../components/meetcha/cardetail/ChalyangGuMaeBangBeob';
import CarBasicInfo from '../../components/meetcha/cardetail/CarBasicInfo';
import CarInquire from '../../components/meetcha/cardetail/CarInquire';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

async function fetcher(url: string) {
    const res = await axios.get(url);
    return res.data;
}

function CarDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const themeMode = getThemeState();
    const [carDetailOnScreen, setCarDetailOnScreen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCarDetailOnScreen(true);
    }, []);

    const { data, error } = useSWR(
        `${meetchaCarDetailAPI}?${query.carNumber ? `carNumber=${query.carNumber}` : `number=${query.number}`}`,
        fetcher,
    );

    useEffect(() => {
        if (error) navigate('/error/404');
    }, [error]);

    const hiddenNavBarRef = useRef<any>();
    const isIntersecting = checkIntersecting(hiddenNavBarRef);

    return (
        <Fragment>
            {data ? (
                <MeetchaDetailPageLayout>
                    {/* 최상단차량정보 */}
                    {isIntersecting ? <HiddenCarDetailInfoNavBar originData={data} /> : null}

                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingLeft: '0.5em',
                            paddingRight: '0.5em',
                            paddingBottom: 30,
                            position: 'relative',
                        }}
                    >
                        {/* 상단 차량번호 */}
                        <TopFixedWrap>
                            <CarNumberTitle>차량번호 {data.result.data[0].car_number}</CarNumberTitle>
                        </TopFixedWrap>

                        {/* 차량 이미지 슬라이더 */}
                        <CarImageSlider originData={data} />

                        <div style={{ marginTop: 20, width: '100%', height: '100%' }}>
                            {/* 차량 기본 정보 */}
                            <CarBasicInfo ref={hiddenNavBarRef} originData={data} />

                            {/* 구독차량설명문구 */}
                            <GudokComment originData={data} />

                            {/* 가격비교 */}
                            <CarPriceCompare originData={data} />

                            {/* 차량정보 */}
                            <CarDetailInfo originData={data} />

                            {/* 차량옵션 */}
                            <CarDetailOption originData={data} />

                            {/* 딜러정보 */}
                            <CarDealerInfo themeMode={themeMode} originData={data} />

                            {/* 판매자 차량 */}
                            <DealerCars originData={data} />

                            {/* 연관방송 */}
                            <CarRelationBroacastList originData={data} />

                            {/* 차량구매방법 */}
                            <ChalyangGuMaeBangBeob originData={data} />
                        </div>
                    </div>

                    {/* 문의하기 */}
                    <CarInquire originData={data} />
                </MeetchaDetailPageLayout>
            ) : (
                <EalryWrap />
            )}
        </Fragment>
    );
}

const EalryWrap = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.bgColor.primary};
`;

const CarNumberTitle = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 18px;
    /* font-weight: bold; */
`;

const TopFixedWrap = styled.div`
    z-index: 1000;
    top: 0;
    position: sticky;
    background-color: ${(props) => props.theme.bgColor.primary};
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default CarDetail;
