import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './DealerCars.css';
import { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';
import { CarPriceStatus, NumberCommaFormat } from '../../common/Util';
import defaultCarImage from '../../../assets/images/defaultImage.jpg';
import { getThemeState, useMclid } from '../../../context/Providers';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Scrollbar, FreeMode } from 'swiper';
import { Link, useNavigate } from 'react-router-dom';

type DealerCarsProps = {
    originData: any;
};

function DealerCars({ originData }: DealerCarsProps) {
    const navigate = useNavigate();
    const dealerCarArr = originData.result.dealer_car_list;
    const themeMode = getThemeState();
    const [mclid, settingMclid] = useMclid();

    // const [clickHandle, setClickHandle] = useState<boolean>(false);
    // const Delay = 10;
    // const scrollRef = useRef<any>(null);
    // const [isDrag, setIsDrag] = useState<boolean>(false);
    // const [startX, setStartX] = useState<any>();

    // function onDragStart(e: any) {
    //     e.preventDefault();
    //     setIsDrag(true);
    //     setClickHandle(true);
    //     setStartX(e.pageX + scrollRef.current.scrollLeft);
    // }

    // function onDragEnd() {
    //     setIsDrag(false);
    // }

    // function onDragMove(e: any) {
    //     if (isDrag) {
    //         setClickHandle(false);
    //         const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
    //         scrollRef.current.scrollLeft = startX - e.pageX;
    //         if (scrollLeft === 0) {
    //             setStartX(e.pageX);
    //         } else if (scrollWidth <= clientWidth + scrollLeft) {
    //             setStartX(e.pageX + scrollLeft);
    //         }
    //     }
    // }

    // function throttle(onDragMove: Function, delayTime: any) {
    //     let throttled = false;
    //     return (...args: any) => {
    //         if (!throttled) {
    //             throttled = true;
    //             setTimeout(() => {
    //                 onDragMove(...args);
    //                 throttled = false;
    //             }, delayTime);
    //         }
    //     };
    // }

    // const onThrottleDragMove = throttle(onDragMove, Delay);

    function goCarDetail(number: string) {
        // navigate(`/car/detail?mclid=${mclid}&theme=${themeMode}&number=${String(number)}`, { replace: true });
        window.location.href = `/car/detail?mclid=${mclid}&theme=${themeMode}&number=${String(number)}`;
        // if (clickHandle) {
        //     setClickHandle(false);
        //     window.open(`/car/detail?theme=${themeMode === 'light' ? 'light' : 'dark'}&number=${String(number)}`);
        // }
    }

    function yymmSplit(yymm: any) {
        if (yymm.includes('-')) {
            const yymmArr: string[] = yymm.split('-');
            const yy: string = yymmArr[0].substring(2.4);
            return `${yy}년${yymmArr[1]}월`;
        }
        return yymm;
    }

    function imageUrl(img1: any) {
        if (img1.includes('d2rxlfguhx92xl') && img1 !== '') return `${img1}&w=400`;
        return img1;
    }

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    return (
        <Fragment>
            {dealerCarArr.length !== 0 ? (
                <div style={{ width: '100%', marginTop: '5em' }}>
                    {/* 타이틀 */}
                    <div>
                        <DealerCarsTitleSpan>판매자 차량</DealerCarsTitleSpan>
                    </div>
                    <div style={{ width: '100%', marginTop: '1.2em' }}>
                        <Swiper
                            freeMode={true}
                            spaceBetween={20}
                            slidesPerView={'auto'}
                            modules={[FreeMode, Pagination, Navigation, Scrollbar]}
                        >
                            {dealerCarArr.map((item: any) => {
                                return (
                                    <SwiperSlide
                                        key={item.number}
                                        style={{ width: 210 }}
                                        onClick={() => {
                                            goCarDetail(item.number);
                                        }}
                                    >
                                        {/* <Link
                                            to={`/car/detail?mclid=${mclid}&theme=${themeMode}&number=${String(
                                                item.number,
                                            )}`}
                                        > */}
                                        <div style={{ cursor: 'pointer', width: '100%', textAlign: 'left' }}>
                                            <div
                                                style={{
                                                    borderRadius: 5,
                                                    overflow: 'hidden',
                                                    width: 210,
                                                    height: 150,
                                                }}
                                            >
                                                <img
                                                    src={imageUrl(item.img1)}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    onError={handleImgOnError}
                                                />
                                            </div>

                                            <div style={{ width: '100%', marginTop: '0.5em' }}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: 45,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <DealerCarsListTitle>{item.title}</DealerCarsListTitle>
                                                </div>
                                                <div style={{ width: '100%', marginTop: 4 }}>
                                                    {item.sale_type === '1' ? (
                                                        <span style={{ color: 'gray', fontSize: '0.75em' }}>
                                                            {yymmSplit(item.ym)}
                                                            &nbsp;&nbsp;·&nbsp;&nbsp;
                                                            {NumberCommaFormat(item.mileage)}
                                                            km&nbsp;&nbsp;·&nbsp;&nbsp;
                                                            {item.si}
                                                        </span>
                                                    ) : (
                                                        <span style={{ color: 'gray', fontSize: '0.75em' }}>
                                                            {yymmSplit(item.ym)}
                                                            &nbsp;&nbsp;·&nbsp;&nbsp;
                                                            {item.si}
                                                        </span>
                                                    )}
                                                </div>
                                                <div style={{ width: '100%', marginTop: '0.4em' }}>
                                                    <span style={{ color: '#FFFFFF', fontSize: '0.9em' }}>
                                                        <CarPriceStatus item={item} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>

                        {/* <DealerCarsListUl
                            ref={scrollRef}
                            onPointerDown={onDragStart}
                            onPointerMove={onThrottleDragMove}
                            onPointerUp={onDragEnd}
                            onPointerLeave={onDragEnd}
                        >
                            {dealerCarArr.map((item: any) => {
                                return (
                                    <li
                                        key={item.number}
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            width: 210,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => goCarDetail(item.number)}
                                    >
                                        <div style={{ borderRadius: 5, overflow: 'hidden', width: 210, height: 150 }}>
                                            <img
                                                src={imageUrl(item.img1)}
                                                width={210}
                                                height={150}
                                                onError={handleImgOnError}
                                            />
                                        </div>
                                        <div style={{ width: '100%', marginTop: '0.5em' }}>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: 45,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <DealerCarsListTitle>{item.title}</DealerCarsListTitle>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                {item.sale_type === '1' ? (
                                                    <span style={{ color: 'gray', fontSize: '0.8em' }}>
                                                        {yymmSplit(item.ym)}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                                                        {NumberCommaFormat(item.mileage)}
                                                        km&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                                                        {item.si}
                                                    </span>
                                                ) : (
                                                    <span style={{ color: 'gray', fontSize: '0.8em' }}>
                                                        {yymmSplit(item.ym)}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                                                        {item.si}
                                                    </span>
                                                )}
                                            </div>
                                            <div style={{ width: '100%', marginTop: '0.4em' }}>
                                                <span style={{ color: '#FFFFFF', fontSize: '1em' }}>
                                                    <CarPriceStatus item={item} />
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </DealerCarsListUl> */}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
}

const DealerCarsTitleSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const DealerCarsListUl = styled.ul`
    touch-action: pan-y;
    list-style: none;
    overflow-x: hidden;
    white-space: nowrap;
    margin: 0px;
    padding: 0px;

    ::-webkit-scrollbar {
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        height: 20%;
        background: gray;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1);
    }
`;

const DealerCarsListTitle = styled.div`
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    display: --webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${(props) => props.theme.text.primary};
    font-size: 0.9em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
    @media (max-width: 430px) {
        font-size: 0.9em;
    }
`;

export default DealerCars;
