import { useEffect, useState } from 'react';

// 스크롤 Ref지점과의 교착여부 체크
export function checkIntersecting(ref: any) {
    const [isIntersecting, setIntersecting] = useState<boolean>(false);
    // let observer: any = null;
    useEffect(() => {
        setTimeout(() => {
            if (!ref.current) return;
            const observer = new IntersectionObserver(([entry]) => setIntersecting(!entry.isIntersecting));
            observer.observe(ref!.current);
        }, 1000);
        // return () => observer.disconnect();
    }, []);
    return isIntersecting;
}

export function useOnLoader(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (!ref.current) return;
            const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
            observer.observe(ref.current);
        }, 500);
        // return () => observer.disconnect();
    }, []);
    return isIntersecting;
}
