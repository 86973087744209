import styled from 'styled-components';
import { ReactComponent as McLogo } from '../../assets/images/mc-logo.svg';

function NotFound() {
    return (
        <Wrapper>
            <ContentWrap>
                <LogoImgWrap>
                    <McLogo width={'100%'} height={'100%'} />
                </LogoImgWrap>
                <ErrorText>페이지를 찾을 수 없습니다.</ErrorText>
            </ContentWrap>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.bgColor.primary};
`;

const ContentWrap = styled.div``;

const LogoImgWrap = styled.div`
    margin: 0 auto;
    width: 100px;
    height: 100px;
    @media (max-width: 430px) {
        width: 90px;
        height: 90px;
    }
`;

const ErrorText = styled.div`
    color: ${(props) => props.theme.text.primary};
    font-size: 1em;
    @media (max-width: 430px) {
        margin-top: 10px;
        font-size: 0.9em;
    }
`;

export default NotFound;
