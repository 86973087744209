import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/images/search-gray.svg';
import { useTheme } from '../../context/Providers';

type McSearchBarProps = {
    val: string;
    placeholder: string;
    onChange: (e: any) => void;
    onKeyPress: (e: any) => void;
};

function McSearchBar({ val, placeholder, onChange, onKeyPress }: McSearchBarProps) {
    const [themeMode, toggleTheme, fixedThemeMode] = useTheme();
    const inInputColor = themeMode === 'light' ? '#3f3f3f' : '#afafaf';
    return (
        <McSearchWrap>
            <McSearchIconWrap>
                <SearchIcon width={'100%'} height={'100%'} stroke={inInputColor} />
            </McSearchIconWrap>
            <McSearchInputWrap
                type={'text'}
                value={val}
                onChange={onChange}
                placeholder={placeholder}
                color={inInputColor}
                onKeyPress={onKeyPress}
            />
        </McSearchWrap>
    );
}

const McSearchWrap = styled.div`
    width: 100%;
    height: 48px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: ${(props) => props.theme.inputBg.primary};
    @media (max-width: 430px) {
        height: 45px;
    }
`;

const McSearchIconWrap = styled.div`
    width: 28px;
    height: 28px;
    margin-right: 10px;
    @media (max-width: 430px) {
        margin-right: 3px;
        width: 26px;
        height: 26px;
    }
`;

const McSearchInputWrap = styled.input.attrs((props: { color: string }) => props)`
    width: 100%;
    height: 100%;
    color: ${(props) => props.color};
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
    font-size: 1.1em;
    border-style: none;
    caret-color: ${(props) => props.color};
    background-color: ${(props) => props.theme.inputBg.primary};
    :focus {
        outline: none;
    }
    ::placeholder {
        color: ${(props) => props.color};
    }

    :-ms-input-placeholder {
        color: ${(props) => props.color};
    }

    ::-ms-input-placeholder {
        color: ${(props) => props.color};
    }
    @media (max-width: 430px) {
        font-size: 1em;
    }
`;

export default McSearchBar;
