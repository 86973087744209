import useSWR from 'swr';
import axios from 'axios';
import styled from 'styled-components';
import { meetchaCarCompareAPI } from '../../../api/MeetchaAPI';
import { Fragment } from 'react';
import CarPriceCompareListItem from './CarPriceCompareListItem';
import { ThreeDots } from 'react-loader-spinner';

type CarPriceCompareProps = {
    originData: any;
};

async function fetcher(url: string) {
    const res = await axios.get(url);
    return res.data;
}

function CarPriceCompare({ originData }: CarPriceCompareProps) {
    const carNumber = originData.result.data[0].car_number;
    const saleType = originData.result.data[0].sale_type;
    const saleEnd = originData.result.data[0].sale_end;

    const { data, error } = useSWR(`${meetchaCarCompareAPI}?carNumber=${carNumber}`, fetcher);

    return (
        <Fragment>
            {saleType === '1' && saleEnd === '0' ? (
                <Fragment>
                    <div style={{ width: '100%', height: '100%', marginTop: '3em' }}>
                        <div>
                            <ComparePriceTitleSpan>동일차량 가격 비교</ComparePriceTitleSpan>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: '1em',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {data ? (
                            data.priceCompareList.length > 0 ? (
                                <CarPriceCompareListItem carCompareData={data} />
                            ) : (
                                <EmptyComparePriceWrap>
                                    <EmptyComparePriceSpan>가격비교 정보가 없습니다.</EmptyComparePriceSpan>
                                </EmptyComparePriceWrap>
                            )
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: 40,
                                        }}
                                    >
                                        <ThreeDotsWrap>
                                            <ThreeDots color="#ff436c" height={'100%'} width={'100%'} />
                                        </ThreeDotsWrap>
                                    </div>
                                    <div>
                                        <CompareLoaderSpan>가격비교중</CompareLoaderSpan>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {data ? (
                        data.priceCompareList.length > 0 ? (
                            <div style={{ marginTop: 5 }}>
                                <ExplanationSpan>* 가격비교는 조회시점에 따라 변동이 있을수 있습니다.</ExplanationSpan>
                            </div>
                        ) : null
                    ) : null}
                </Fragment>
            ) : null}
        </Fragment>
    );
}

const ComparePriceTitleSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const ThreeDotsWrap = styled.div`
    width: 50px;
    @media (max-width: 430px) {
        width: 40px;
    }
`;

const CompareLoaderSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1em;
    /* font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)}; */
    @media (max-width: 430px) {
        font-size: 1em;
    }
`;

const EmptyComparePriceWrap = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1.5px;
    border-style: solid;
    border-color: ${(props) => props.theme.borderColor.primary};
`;

const EmptyComparePriceSpan = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
    font-size: 0.85em;
`;

const ExplanationSpan = styled.span`
    color: #6f6f6f;
    font-size: 0.8em;
`;

export default CarPriceCompare;
