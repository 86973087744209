import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Fragment } from 'react';

type GudokCommentProps = {
    originData: any;
};

function GudokComment({ originData }: GudokCommentProps) {
    const saleType = originData.result.data[0].sale_type;
    return (
        <Fragment>
            {saleType === '5' ? (
                <div style={{ marginTop: '2em' }}>
                    <div>
                        <GudokTitle>구독서비스란?</GudokTitle>
                    </div>
                    <div
                        style={{
                            marginTop: '0.8em',
                            borderWidth: 2,
                            borderColor: 'gray',
                            borderStyle: 'solid',
                            padding: '0.5em',
                        }}
                    >
                        <GudokContent>
                            저신용자도 매월 정해진 금액을 지불하고 자동차를 이용하실 수 있는 서비스입니다.
                        </GudokContent>
                        <div style={{ marginTop: '1.5em' }}>
                            <GudokFristInquiry>* 이용방법</GudokFristInquiry>
                            <GudokSecondInquiry>
                                구독문의로 담당자와 상세한 상담 통화 후 이용 가능 합니다.
                            </GudokSecondInquiry>
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
}

const gudokTitleSize: string = isMobile ? '1.2em' : '1.3em';
const contentSize: string = isMobile ? '1.0em' : '1.1em';

const GudokTitle = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: ${gudokTitleSize};
    font-weight: bold;
`;

const GudokContent = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: ${contentSize};
    display: block;
`;

const GudokFristInquiry = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: ${contentSize};
    display: block;
`;

const GudokSecondInquiry = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: ${contentSize};
`;

export default GudokComment;
