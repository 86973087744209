import useSWR from 'swr';
import axios from 'axios';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { useTheme } from '../../../context/Providers';
import { ReactComponent as Caretdown } from '../../../assets/images/caretdown.svg';
import { meetchaBroadcastListAPI } from '../../../api/MeetchaAPI';
import { NumberCommaFormat } from '../../common/Util';
import { useSpring, animated } from 'react-spring';
import './BroadcastStatusBar.css';

// caretdown.svg
type BroadcastStatusBarProps = {
    contentInterval: string;
    howtoSort: string;
    setHowtoSort: React.Dispatch<React.SetStateAction<string>>;
    searchKeyword: string;
};

type BrodSortTypeListType = {
    sortTitle: string;
    sortType: string;
};

async function fetcher(url: string) {
    const res = await axios.get(url);
    return res.data.listSize;
}

function BroadcastStatusBar({ contentInterval, howtoSort, setHowtoSort, searchKeyword }: BroadcastStatusBarProps) {
    const selectRef = useRef<any>();
    const [themeMode, toggleTheme, fixedThemeMode] = useTheme();
    const [onSelectList, setOnSelectList] = useState<boolean>(false);
    const broadSortTypeList: BrodSortTypeListType[] = [{ sortTitle: '최신방송순', sortType: 'broadcastStartDt' }];

    const { data: count, error } = useSWR(
        `${meetchaBroadcastListAPI}?searchType=search&howtoSort=${howtoSort}&searchKeyword=${searchKeyword}`,
        fetcher,
    );

    const rsProps = useSpring({
        width: count && !error ? count : 0,
        config: { duration: 400 },
    });

    // const clickSelectOutside = (e: any) => {
    //     if (onSelectList && !selectRef.current.contains(e.target)) setOnSelectList(false);
    // };
    // useEffect(() => {
    //     document.addEventListener('mousedown', clickSelectOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', clickSelectOutside);
    //     };
    // });

    return (
        <StatusBar contentInterval={contentInterval}>
            <BroadCountWrap>
                {/* 전체 <BroadCountNum>{count && !error ? NumberCommaFormat(String(count)) : 0}</BroadCountNum> 건 */}
                전체{' '}
                <animated.span className={'broad_count_num'}>
                    {count && !error
                        ? rsProps.width.to((x: any) => NumberCommaFormat(String(Math.floor((x * count) / count))))
                        : 0}
                </animated.span>{' '}
                건
            </BroadCountWrap>

            <StatusSelecterWrap
                onClick={() => {
                    broadSortTypeList.length > 1 ? setOnSelectList(!onSelectList) : null;
                }}
            >
                <StatusSelecter>
                    {
                        broadSortTypeList[broadSortTypeList.findIndex((element) => element.sortType === howtoSort)]
                            .sortTitle
                    }
                </StatusSelecter>
                {broadSortTypeList.length > 1 ? (
                    <StatusSelecterIcon>
                        <Caretdown width={'100%'} height={'100%'} stroke={'gray'} />
                    </StatusSelecterIcon>
                ) : null}
            </StatusSelecterWrap>

            {onSelectList ? (
                <StatusSelecterOptWrap ref={selectRef}>
                    {broadSortTypeList.map((list: any) => (
                        <StatusSelecterOpt
                            key={list.sortType}
                            onClick={() => {
                                setHowtoSort(list.sortType);
                                setOnSelectList(false);
                            }}
                        >
                            {list.sortTitle}
                        </StatusSelecterOpt>
                    ))}
                </StatusSelecterOptWrap>
            ) : null}
        </StatusBar>
    );
}

export default BroadcastStatusBar;

const StatusBar = styled.div.attrs((props: { contentInterval: string }) => props)`
    background-color: ${(props) => props.theme.bgColor.primary};
    margin-top: ${(props) => props.contentInterval};
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 430px) {
        height: 35px;
    }
`;

const StatusSelecterWrap = styled.div`
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    padding: 8px 15px;
    @media (max-width: 430px) {
        padding: 5px 13px;
    }
`;

const StatusSelecter = styled.div`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.1em;
    @media (max-width: 430px) {
        font-size: 0.8em;
    }
`;

const BroadCountWrap = styled.div`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.1em;
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 0.95em;
    }
`;

const BroadCountNum = styled.span`
    color: #ff436c;
    font-size: 1.3em;
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.4em;
    }
`;

const StatusSelecterIcon = styled.div`
    margin-left: 0.3em;
    width: 20px;
    height: 20px;
    @media (max-width: 430px) {
        margin-left: 0.2em;
        width: 15px;
        height: 15px;
    }
`;

const StatusSelecterOptWrap = styled.ul`
    cursor: pointer;
    overflow: hidden;
    z-index: 500;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 200px;
    top: 45px;
    right: 0;
    background-color: ${(props) => props.theme.bgColor.primary};
    border-radius: 10px;
    box-shadow: ${(props) =>
        props.theme.state === 'light' ? '0 5px 10px rgb(0 0 0 / 60%)' : '0 5px 10px rgb(255 255 255 / 50%)'};
    @media (max-width: 430px) {
        top: 32px;
        width: 140px;
    }
`;

const StatusSelecterOpt = styled.li`
    display: flex;
    padding: 0.6rem 1rem;
    font-size: 1em;
    color: ${(props) => props.theme.text.primary};
    border-bottom: ${(props) => (props.theme.state === 'light' ? '1px solid #e7e4e4;' : '1px solid #777676;')};
    @media (max-width: 430px) {
        font-size: 0.8em;
    }
`;
