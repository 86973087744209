import './App.css';
import NotFound from './pages/error/NotFound';
import CarDetail from './pages/meetcha/CarDetail';
import BraodcastDetail from './pages/meetcha/BroadcastDetail';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BigchaMainCarList from './pages/bigcha/BigchaMainCarList';
import { Providers } from './context/Providers';
import BroadcastList from './pages/meetcha/BroadcastList';
import PrerenderComponent from './components/service/PrerenderComponent';

function App() {
    return (
        <BrowserRouter>
            <Providers>
                <Routes>
                    {/* MEETCHA */}
                    <Route path="/" element={<BigchaMainCarList />}></Route>
                    <Route
                        path="/car/detail"
                        element={
                            <PrerenderComponent>
                                <CarDetail />
                            </PrerenderComponent>
                        }
                    ></Route>
                    <Route
                        path="/broadcast/detail"
                        element={
                            <PrerenderComponent>
                                <BraodcastDetail />
                            </PrerenderComponent>
                        }
                    ></Route>
                    <Route
                        path="/broadcast/list"
                        element={
                            <PrerenderComponent>
                                <BroadcastList />
                            </PrerenderComponent>
                        }
                    ></Route>
                    {/* BIGCHA */}
                    <Route
                        path="/bigcha/main/carlist"
                        element={
                            <PrerenderComponent>
                                <BigchaMainCarList />
                            </PrerenderComponent>
                        }
                    ></Route>
                    {/* ERROR PAGE */}
                    <Route path="*" element={<NotFound />}></Route>
                </Routes>
            </Providers>
        </BrowserRouter>
    );
}

export default App;
