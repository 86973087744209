import useSWR from 'swr';
import axios from 'axios';
import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import useSWRInfinite from 'swr/infinite';
import { TailSpin } from 'react-loader-spinner';
import { getThemeState } from '../../context/Providers';
import { meetchaBroadcastListAPI, meetchaBannerAPI } from '../../api/MeetchaAPI';
import MeetchaDetailPageLayout from '../../components/layout/MeetchaDetailPageLayout';
import McSearchBar from '../../components/element/McSearchBar';
import { useOnLoader } from '../../customhooks/CustomHooks';
import BroadcastListItem from '../../components/meetcha/broadcastlist/BroadcastListItem';
import BroadcastStatusBar from '../../components/meetcha/broadcastlist/BroadcastStatusBar';
import BroadcastMainBannerList from '../../components/meetcha/broadcastlist/BroadcastMainBannerList';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE: number = 12;

const ContentInterval = '0.8em';
const ContentHeightIerval = '0.7em';

async function broadListfetcher(url: string) {
    const res = await axios.get(url);
    return res.data.broadcastList;
}

async function bnannerListfetcher(url: string) {
    const res = await axios.get(url);
    return res.data;
}

function getBroadListKey(index: number, previousPageData: any, howtoSort: string, searchKeyword: string) {
    if (index !== 0) index = index * PAGE_SIZE;
    if (previousPageData && !previousPageData.length) return null;
    return `${meetchaBroadcastListAPI}?searchType=search&howtoSort=${howtoSort}&searchKeyword=${searchKeyword}&page=${index}`;
}

function BroadcastList() {
    const navigate = useNavigate();
    const themeMode = getThemeState();

    // 테마초기화
    useEffect(() => {
        sessionStorage.setItem('onScreen', 'true');
        setOnScreen(true);
    }, []);

    const loaderScrollRef: any = useRef();
    const statusBarRef: any = useRef();
    const [howtoSort, setHowtoSort] = useState<string>('broadcastStartDt');
    const [searchKeyword, setSearchKeyword] = useState<string>(
        sessionStorage.getItem('searchKeyword') ? sessionStorage.getItem('searchKeyword')! : '',
    );
    const [searchInputVal, setSearchInputVal] = useState<string>(searchKeyword);
    const [onScreen, setOnScreen] = useState<boolean>(false);

    // 페이지 호출
    const isLoaderVisible = useOnLoader(loaderScrollRef);

    // 데이터
    const { data: bannerList, error: bannerError } = useSWR(`${meetchaBannerAPI}?gubun=1`, bnannerListfetcher);
    const {
        data: broadList,
        error: broadListError,
        mutate,
        size,
        setSize,
        isValidating,
    } = useSWRInfinite((...args) => getBroadListKey(...args, howtoSort, searchKeyword), broadListfetcher);

    useEffect(() => {
        if (broadListError) navigate('/error/404');
    }, [broadListError]);

    // 방송리스트
    const dataList: never[] = broadList ? [].concat(...broadList) : [];
    const isLoadingInitialData: boolean = !broadList && !broadListError;
    const isLoadingMore: boolean | undefined =
        isLoadingInitialData || (size > 0 && broadList && typeof broadList[size - 1] === 'undefined');
    const isEmpty: boolean = broadList?.[0]?.length === 0;
    const isReachingEnd: boolean | undefined = broadList && broadList[broadList.length - 1]?.length < PAGE_SIZE;
    const isRefreshing: any = isValidating && broadList && broadList.length === size;

    useEffect(() => {
        if (isLoaderVisible && !isReachingEnd && !isRefreshing) setSize(size + 1);
    }, [isLoaderVisible, isRefreshing]);

    useEffect(() => {
        if (onScreen) window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [howtoSort, searchKeyword]);

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => {
            document.removeEventListener('beforeunload', onUnload);
        };
    });

    useEffect(() => {
        window.addEventListener('unload', onUnload);
        return () => {
            document.removeEventListener('unload', onUnload);
        };
    });

    function onUnload() {
        sessionStorage.clear();
    }

    function broadItemClick() {
        // const [scrollPosition, setScrollPosition] = useState(0);
        // useEffect(() => {
        //     window.addEventListener('scroll', updateScroll);
        // });
        // const updateScroll = () => {
        //     setScrollPosition(window.scrollY || document.documentElement.scrollTop);
        // };
        // sessionStorage.setItem('scrollY', String(scrollPosition));
    }

    function inputOnChange(e: any) {
        setSearchInputVal(e.target.value);
    }

    function handleOnClick() {
        sessionStorage.setItem('searchKeyword', searchInputVal);
        setSearchKeyword(searchInputVal);
        setSize(1);
    }

    function onKeyPress(e: any) {
        if (e.key === 'Enter') {
            handleOnClick();
            e.target.blur();
        }
    }

    return (
        <Fragment>
            {sessionStorage.getItem('onScreen') === 'true' || onScreen ? (
                <MeetchaDetailPageLayout>
                    <Wrap>
                        <TopMcSearchBarWrap>
                            <McSearchBar
                                val={searchInputVal}
                                placeholder={'바로 방송을 검색해보세요.'}
                                onChange={inputOnChange}
                                onKeyPress={onKeyPress}
                            />
                        </TopMcSearchBarWrap>

                        {/* 배너 */}
                        {bannerList && !bannerError ? (
                            <BroadcastMainBannerList bannerList={bannerList} />
                        ) : (
                            <BannerWrap />
                        )}

                        {/* 상태바 */}
                        <PaddingWrap ref={statusBarRef}>
                            <BroadcastStatusBar
                                contentInterval={ContentInterval}
                                howtoSort={howtoSort}
                                setHowtoSort={setHowtoSort}
                                searchKeyword={searchKeyword}
                            />
                        </PaddingWrap>

                        {/* 방송리스트 */}
                        <PaddingWrap>
                            <BroadcastListWrap>
                                {broadList ? (
                                    dataList.length !== 0 ? (
                                        dataList.map((listItem: any) => {
                                            return (
                                                <BroadcastListItem
                                                    key={listItem.seq}
                                                    listItem={listItem}
                                                    broadItemClick={broadItemClick}
                                                />
                                            );
                                        })
                                    ) : (
                                        <DataStatusWrap>
                                            <SearchEmptySpan>검색하신 방송이 없습니다.</SearchEmptySpan>
                                        </DataStatusWrap>
                                    )
                                ) : (
                                    <DataStatusWrap>
                                        <TailSpinWrap>
                                            <TailSpin
                                                color={themeMode === 'light' ? '#000000' : '#818181'}
                                                height={'100%'}
                                                width={'100%'}
                                            />
                                        </TailSpinWrap>
                                    </DataStatusWrap>
                                )}

                                <LoadingWrap ref={loaderScrollRef}>
                                    {broadList && isLoadingMore && dataList.length !== 0 ? (
                                        <LoadingSecondWrap>
                                            <TailSpinWrap>
                                                <TailSpin
                                                    color={themeMode === 'light' ? '#000000' : '#818181'}
                                                    height={'100%'}
                                                    width={'100%'}
                                                />
                                            </TailSpinWrap>
                                        </LoadingSecondWrap>
                                    ) : null}
                                </LoadingWrap>
                            </BroadcastListWrap>
                        </PaddingWrap>
                    </Wrap>
                </MeetchaDetailPageLayout>
            ) : (
                <EalryWrap />
            )}
        </Fragment>
    );
}

const EalryWrap = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.bgColor.primary};
`;

const BannerWrap = styled.div`
    width: 100%;
    height: 600px;
    background-color: ${(props) => props.theme.bgColor.primary};
    @media (max-width: 430px) {
        height: 400px;
    }
`;

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.bgColor.primary};
`;

const PaddingWrap = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 ${ContentHeightIerval};
    background-color: ${(props) => props.theme.bgColor.primary};
`;

const BroadcastListWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    margin-top: ${ContentInterval};
    width: 100%;
`;

const TopMcSearchBarWrap = styled.div`
    top: 0;
    z-index: 1000;
    position: sticky;
    padding: ${ContentInterval} 0;
    background-color: ${(props) => props.theme.bgColor.primary};
    width: 100%;
    height: 100%;
    padding: ${ContentHeightIerval} ${ContentHeightIerval};
`;

const DataStatusWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    width: 100%;
    height: calc(100vh - 733.19px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 430px) {
        height: calc(100vh - 515.2px);
    }
`;

const SearchEmptySpan = styled.span`
    background-color: ${(props) => props.theme.bgColor.primary};
    font-size: 1em;
    color: ${(props) => props.theme.text.primary};
    @media (max-width: 430px) {
    }
`;

const TailSpinWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    width: 50px;
    height: 50px;
`;

const LoadingWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    padding: 15px 0;
`;

const LoadingSecondWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor.primary};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default BroadcastList;
