import moment from 'moment';
import { Fragment } from 'react';
import styled from 'styled-components';

type CarPriceStatusProps = {
    item: any;
};
type ItemCarPriceStatus = {
    item: any;
};
type CarPriceStatus2Props = {
    item: any;
};
type CarPriceStatus3Props = {
    item: any;
};

// 1
const carPriceStatusStyleValue = {
    accentColor: '#FF436C',
    textSize: '0.95em',
    priceTextSize: '1.1em',
    smallTextSize: '0.75em',
};

const WolSpan1 = styled.span`
    font-size: ${carPriceStatusStyleValue.textSize};
    color: ${(props) => props.theme.text.primary};
`;

const PriceSpan1 = styled.span`
    font-size: ${carPriceStatusStyleValue.priceTextSize};
    color: ${carPriceStatusStyleValue.accentColor};
    font-weight: bold;
`;

const ManWonSpan1 = styled.span`
    font-size: ${carPriceStatusStyleValue.textSize};
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
`;

const GubunSpan1 = styled.span`
    font-size: ${carPriceStatusStyleValue.smallTextSize};
    color: ${carPriceStatusStyleValue.accentColor};
`;

const PanmaeanlyoSpan1 = styled.span`
    font-size: ${carPriceStatusStyleValue.textSize};
    color: ${carPriceStatusStyleValue.accentColor};
    font-weight: bold;
`;

// 2
const carPriceStatusStyleValue2 = {
    textSize: '1em',
    priceTextSize: '1.1em',
    smallTextSize: '0.75em',
};

const WolSpan2 = styled.span`
    font-size: ${carPriceStatusStyleValue2.textSize};
    color: #000000;
`;

const PriceSpan2 = styled.span`
    font-size: ${carPriceStatusStyleValue2.priceTextSize};
    color: #ff436c;
    font-weight: bold;
`;

const ManWonSpan2 = styled.span`
    font-size: ${carPriceStatusStyleValue2.textSize};
    color: #000000;
    font-weight: bold;
`;

const GubunSpan2 = styled.span`
    font-size: ${carPriceStatusStyleValue2.smallTextSize};
    color: #ff436c;
`;

const PanmaeanlyoSpan2 = styled.span`
    font-size: ${carPriceStatusStyleValue2.textSize};
    color: #ff436c;
    font-weight: bold;
`;

// 3
const carPriceStatusStyleValue3 = {
    textSize: '1em',
    priceTextSize: '1.1em',
    smallTextSize: '0.75em',
};

const WolSpan3 = styled.span`
    font-size: ${carPriceStatusStyleValue3.textSize};
    color: #000000;
`;

const PriceSpan3 = styled.span`
    font-size: ${carPriceStatusStyleValue3.priceTextSize};
    color: #ff436c;
    font-weight: bold;
`;

const ManWonSpan3 = styled.span`
    font-size: ${carPriceStatusStyleValue3.textSize};
    color: #000000;
    font-weight: bold;
`;

const GubunSpan3 = styled.span`
    font-size: ${carPriceStatusStyleValue3.smallTextSize};
    color: #ff436c;
`;

const PanmaeanlyoSpan3 = styled.span`
    font-size: ${carPriceStatusStyleValue3.textSize};
    color: #ff436c;
    font-weight: bold;
`;

//special
const ItemCarPriceStatusStyleValue = {
    accentColor: '#FF436C',
    textSize: '1.35em',
    priceTextSize: '2em',
    saleEndTextSize: '1.6em',
    smallTextSize: '1.1em',
};

const WolSpanItem = styled.span`
    font-size: ${ItemCarPriceStatusStyleValue.textSize};
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.15em;
    }
`;

const PriceSpanItem = styled.span`
    font-size: ${ItemCarPriceStatusStyleValue.priceTextSize};
    color: ${ItemCarPriceStatusStyleValue.accentColor};
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.6em;
    }
`;

const ManWonSpanItem = styled.span`
    font-size: ${ItemCarPriceStatusStyleValue.textSize};
    color: ${(props) => props.theme.text.primary};
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.15em;
    }
`;

const GubunSpanItem = styled.span`
    font-size: ${ItemCarPriceStatusStyleValue.smallTextSize};
    color: ${ItemCarPriceStatusStyleValue.accentColor};
    @media (max-width: 430px) {
        font-size: 0.8em;
    }
`;

const PanmaeanlyoSpanItem = styled.span`
    font-size: ${ItemCarPriceStatusStyleValue.saleEndTextSize};
    color: ${ItemCarPriceStatusStyleValue.accentColor};
    font-weight: bold;
    @media (max-width: 430px) {
        font-size: 1.4em;
    }
`;

//3자리 마다 콤마 처리
export const NumberCommaFormat = (stringNumber: string) => {
    return stringNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const timeForYYMM = (time: any) => {
    return moment(time).format('YY년MM월');
};

//차량 가격 설정
export function CarPriceStatus({ item }: CarPriceStatusProps) {
    if (String(item.sale_end) === '1') {
        return <PanmaeanlyoSpan1>판매완료</PanmaeanlyoSpan1>;
    } else {
        if (item.group1 === '렌트') {
            return (
                <Fragment>
                    <WolSpan1>월 </WolSpan1>
                    <PriceSpan1>{item.price}</PriceSpan1>
                    <ManWonSpan1>만원</ManWonSpan1>
                    <GubunSpan1> (렌터카)</GubunSpan1>
                </Fragment>
            );
        } else if (item.group1 === '리스') {
            return (
                <Fragment>
                    <WolSpan1>월 </WolSpan1>
                    <PriceSpan1>{item.price}</PriceSpan1>
                    <ManWonSpan1>만원</ManWonSpan1>
                    <GubunSpan1> ({item.group1})</GubunSpan1>
                </Fragment>
            );
        } else if (item.sale_type === '5') {
            return (
                <Fragment>
                    <WolSpan1>월 </WolSpan1>
                    <PriceSpan1>{item.price}</PriceSpan1>
                    <ManWonSpan1>만원</ManWonSpan1>
                    <GubunSpan1> (구독)</GubunSpan1>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <PriceSpan1>{item.price}</PriceSpan1>
                    <ManWonSpan1>만원</ManWonSpan1>
                </Fragment>
            );
        }
    }
}

export function ItemCarPriceStatus({ item }: ItemCarPriceStatus) {
    if (item.saleEnd !== 0) {
        return <PanmaeanlyoSpanItem>판매완료</PanmaeanlyoSpanItem>;
    } else {
        if (item.group1 === '렌트') {
            return (
                <Fragment>
                    <WolSpanItem>월 </WolSpanItem>
                    <PriceSpanItem>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                    <ManWonSpanItem>만원</ManWonSpanItem>
                    <GubunSpanItem> (렌터카)</GubunSpanItem>
                </Fragment>
            );
        } else if (item.group1 === '리스') {
            return (
                <Fragment>
                    <WolSpanItem>월 </WolSpanItem>
                    <PriceSpanItem>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                    <ManWonSpanItem>만원</ManWonSpanItem>
                    <GubunSpanItem> ({item.group1})</GubunSpanItem>
                </Fragment>
            );
        } else if (item.saleType === '5') {
            return (
                <Fragment>
                    <WolSpanItem>월 </WolSpanItem>
                    <PriceSpanItem>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                    <ManWonSpanItem>만원</ManWonSpanItem>
                    <GubunSpanItem> (구독)</GubunSpanItem>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <PriceSpanItem>{NumberCommaFormat(String(item.price))}</PriceSpanItem>
                    <ManWonSpanItem>만원</ManWonSpanItem>
                </Fragment>
            );
        }
    }
}

export function CarPriceStatus2({ item }: CarPriceStatus2Props) {
    if (String(item.saleEnd) !== '0') {
        return <PanmaeanlyoSpan2>판매완료</PanmaeanlyoSpan2>;
    } else {
        if (item.group1 === '렌트') {
            return (
                <Fragment>
                    <WolSpan2>월 </WolSpan2>
                    <PriceSpan2>{NumberCommaFormat(String(item.price))}</PriceSpan2>
                    <ManWonSpan2>만원</ManWonSpan2>
                    <GubunSpan2> (렌터카)</GubunSpan2>
                </Fragment>
            );
        } else if (item.group1 === '리스') {
            return (
                <Fragment>
                    <WolSpan2>월 </WolSpan2>
                    <PriceSpan2>{NumberCommaFormat(String(item.price))}</PriceSpan2>
                    <ManWonSpan2>만원</ManWonSpan2>
                    <GubunSpan2> ({item.group1})</GubunSpan2>
                </Fragment>
            );
        } else if (String(item.saleType) === '5') {
            return (
                <Fragment>
                    <WolSpan2>월 </WolSpan2>
                    <PriceSpan2>{NumberCommaFormat(String(item.price))}</PriceSpan2>
                    <ManWonSpan2>만원</ManWonSpan2>
                    <GubunSpan2> (구독)</GubunSpan2>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <PriceSpan2>{NumberCommaFormat(String(item.price))}</PriceSpan2>
                    <ManWonSpan2>만원</ManWonSpan2>
                </Fragment>
            );
        }
    }
}

export function CarPriceStatus3({ item }: CarPriceStatus3Props) {
    if (String(item.price) === '0' || String(item.saleEnd) === '1') {
        return <PanmaeanlyoSpan3>판매완료</PanmaeanlyoSpan3>;
    } else if (item.group1 === '렌트') {
        return (
            <Fragment>
                <WolSpan3>월 </WolSpan3>
                <PriceSpan3>{NumberCommaFormat(String(item.price))}</PriceSpan3>
                <ManWonSpan3>만원</ManWonSpan3>
                <GubunSpan3> (렌터카)</GubunSpan3>
            </Fragment>
        );
    } else if (item.group1 === '리스') {
        return (
            <Fragment>
                <WolSpan3>월 </WolSpan3>
                <PriceSpan3>{NumberCommaFormat(String(item.price))}</PriceSpan3>
                <ManWonSpan3>만원</ManWonSpan3>
                <GubunSpan3> ({item.group1})</GubunSpan3>
            </Fragment>
        );
    } else if (item.saleType === '5') {
        return (
            <Fragment>
                <WolSpan3>월 </WolSpan3>
                <PriceSpan3>{NumberCommaFormat(String(item.price))}</PriceSpan3>
                <ManWonSpan3>만원</ManWonSpan3>
                <GubunSpan3> (구독)</GubunSpan3>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <PriceSpan3>{NumberCommaFormat(String(item.price))}</PriceSpan3>
                <ManWonSpan3>만원</ManWonSpan3>
            </Fragment>
        );
    }
}

export const timeForToday = (time: any) => {
    const today = new Date();
    const timeValue = new Date(time);
    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (betweenTime < 1) return '방금전';
    if (betweenTime < 60) {
        return `${betweenTime}분전`;
    }
    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
    }
    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 11) {
        return `${betweenTimeDay}일전`;
    }
    return moment(time).format('YY.MM.DD');
};
