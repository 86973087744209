import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react';
import { timeForToday } from '../../common/Util';
import { useMclid } from '../../../context/Providers';

type CarRelationBroacastListProps = {
    originData: any;
};

function CarRelationBroacastList({ originData }: CarRelationBroacastListProps) {
    const Delay = 10;
    const [mclid, settingMclid] = useMclid();
    const carRelationBroadcastArr = originData.result.video_list;
    const scrollRef = useRef<any>(null);
    const [clickHandle, setClickHandle] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const [startX, setStartX] = useState<any>();

    function onDragStart(e: any) {
        e.preventDefault();
        setIsDrag(true);
        setClickHandle(true);
        setStartX(e.pageX + scrollRef.current.scrollLeft);
    }

    function onDragEnd() {
        setIsDrag(false);
    }

    function onDragMove(e: any) {
        if (isDrag) {
            setClickHandle(false);
            const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
            scrollRef.current.scrollLeft = startX - e.pageX;
            if (scrollLeft === 0) {
                setStartX(e.pageX);
            } else if (scrollWidth <= clientWidth + scrollLeft) {
                setStartX(e.pageX + scrollLeft);
            }
        }
    }

    function throttle(onDragMove: Function, delayTime: any) {
        let throttled = false;
        return (...args: any) => {
            if (!throttled) {
                throttled = true;
                setTimeout(() => {
                    onDragMove(...args);
                    throttled = false;
                }, delayTime);
            }
        };
    }

    const onThrottleDragMove = throttle(onDragMove, Delay);

    return (
        <Fragment>
            {carRelationBroadcastArr.length !== 0 ? (
                <div style={{ width: '100%', marginTop: '4em' }}>
                    {/* 타이틀 */}
                    <div>
                        <CarRelationTitle>연관방송</CarRelationTitle>
                        <div style={{ width: '100%', marginTop: '1.2em' }}>
                            <CarRelationUl
                                ref={scrollRef}
                                onPointerDown={onDragStart}
                                onPointerMove={onThrottleDragMove}
                                onPointerUp={onDragEnd}
                                onPointerLeave={onDragEnd}
                            >
                                {carRelationBroadcastArr.map((item: any, index: number) => {
                                    return (
                                        <Link
                                            key={item.seq}
                                            to={`/broadcast/detail?mclid=${mclid}&seq=${item.seq}&liveType=${item.live_type}&wdtbSttus=${item.wdtb_sttus}`}
                                        >
                                            <LiWrap>
                                                <div style={{ position: 'relative' }}>
                                                    <BroadImgWrap>
                                                        <img
                                                            src={`${item.origin_domain}${item.origin_rest_url}`}
                                                            width={'100%'}
                                                            height={'100%'}
                                                        />
                                                    </BroadImgWrap>
                                                    {item.live_type === 'L' ? (
                                                        <div
                                                            style={{
                                                                borderRadius: 20,
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                paddingTop: 1,
                                                                paddingBottom: 1,
                                                                position: 'absolute',
                                                                backgroundColor: '#FF436C',
                                                                top: 8,
                                                                left: 8,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: '#FFFFFF',
                                                                    fontSize: '0.7em',
                                                                    marginTop: 1,
                                                                }}
                                                            >
                                                                ON AIR
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div style={{ width: '100%', marginTop: 5 }}>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: 47,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <CarRelationTitleSpan>{item.title}</CarRelationTitleSpan>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            // justifyContent: 'space-between',
                                                            marginTop: '0.4em',
                                                        }}
                                                    >
                                                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                                                            믿차 {item.user_nick}
                                                        </span>
                                                        {/* <span style={{ color: 'gray', fontSize: '0.82em' }}>·</span> */}
                                                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                                                            &nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </span>
                                                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                                                            {timeForToday(item.broadcast_start_dt)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </LiWrap>
                                        </Link>
                                    );
                                })}
                            </CarRelationUl>
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
}

const CarRelationTitle = styled.span`
    color: ${(props) => props.theme.text.primary};
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const CarRelationUl = styled.ul`
    touch-action: pan-y;
    list-style: none;
    overflow-x: hidden;
    white-space: nowrap;
    margin: 0px;
    padding: 0px;

    ::-webkit-scrollbar {
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        height: 20%;
        background: gray;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1);
    }
`;

const CarRelationTitleSpan = styled.span`
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${(props) => props.theme.text.primary};
    font-size: 0.95em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const LiWrap = styled.li`
    /* display: 'inline-block',
    marginRight: '0.8em',
    width: 150,
    cursor: 'pointer', */
    display: inline-block;
    margin-right: 2em;
    width: 150px;
    cursor: pointer;
    @media (max-width: 430px) {
        width: 140px;
        margin-right: 0.8em;
    }
`;

const BroadImgWrap = styled.div`
    /* width: 150,
    height: 210,
    borderRadius: 5,
    overflow: 'hidden', */
    width: 150px;
    height: 210px;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: 430px) {
        width: 140px;
        height: 200px;
    }
`;

export default CarRelationBroacastList;
