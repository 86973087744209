import { Fragment } from 'react';
import styled from 'styled-components';

type ChalyangGuMaeBangBeobProps = {
    originData: any;
};

function ChalyangGuMaeBangBeob({ originData }: ChalyangGuMaeBangBeobProps) {
    const carSaleType = originData.result.data[0].sale_type;

    return (
        <Fragment>
            {carSaleType === '1' ? (
                <div style={{ width: '100%', marginTop: 70, paddingBottom: 30 }}>
                    {/* 1 */}
                    <BottomFirstSpan>- 허위매물을 조심하세요.</BottomFirstSpan>
                    <BottomSecondSpan>판매금액,사고유무, 명시되어 있는 옵션 등을 꼼꼼히 확인 합니다.</BottomSecondSpan>
                    {/* 2 */}
                    <BottomFirstSpan>- 자동 성능/상태 점검 기록부를 확인하세요.</BottomFirstSpan>
                    <BottomSecondSpan>
                        자동 성능/상태 점검 기록부에는 자동차의 성능 및 교환/판금 여부를 확인 가능 합니다.
                    </BottomSecondSpan>
                    {/* 3 */}
                    <BottomFirstSpan>- 압류 또는 저당 매물인지 확인하세요.</BottomFirstSpan>
                    <BottomSecondSpan>
                        1. 해당 차량의 매뉴얼이 있는지 확인합니다.
                        <br />
                        2. 에어컨과 히터의 상태를 확인합니다.
                        <br />
                        3. 보조키의 유무를 확인합니다.
                        <br />
                        4. 스페어 타이어의 이상유무를 확인합니다.
                        <br />
                        5. 네이게이션이 있다면 차량을 운행해보면서 정상작동하는지 확인합니다.
                    </BottomSecondSpan>
                </div>
            ) : (
                <div style={{ height: 60 }}></div>
            )}
        </Fragment>
    );
}

const BottomFirstSpan = styled.p`
    color: ${(props) => props.theme.text.primary};
    font-size: 0.93em;
    cursor: pointer;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

const BottomSecondSpan = styled.p`
    color: gray;
    font-size: 0.91em;
    cursor: pointer;
    @media (max-width: 430px) {
        font-size: 0.8em;
    }
`;

export default ChalyangGuMaeBangBeob;
