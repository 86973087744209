import { useEffect } from 'react';
import queryString from 'query-string';
import { Navigate, useLocation } from 'react-router-dom';
import { useMclid, useTheme } from '../../context/Providers';
import { checkPageIdentifier } from '../../service/PageIdentifierService';

const ignorePathName: string[] = ['/bigcha/main/carlist'];

function PrerenderComponent({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const pathName = location.pathname;
    const query = queryString.parse(location.search);
    const [mclid, settingMclid] = useMclid();
    const [themeMode, toggleTheme, fixedThemeMode] = useTheme();

    useEffect(() => {
        if (typeof query.theme === 'string' && ignorePathName.indexOf(pathName) !== 0) {
            fixedThemeMode(query.theme);
        }
        if (typeof query.mclid === 'string' && checkPageIdentifier(query.mclid)) {
            settingMclid(query.mclid);
        }
    }, []);

    if (typeof query.mclid !== 'string' || !checkPageIdentifier(query.mclid)) {
        return <Navigate to="/page/404" replace />;
    } else {
        return children;
    }
}

export default PrerenderComponent;
