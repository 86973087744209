import { useState } from 'react';
import styled from 'styled-components';
import CarDetailOptionModal from '../../modal/CarDetailOptionModal';

type CarDetailOptionProps = {
    originData: any;
};

function CarDetailOption({ originData }: CarDetailOptionProps) {
    const carOptSpecial = originData.result.opt_special;
    const carOptins = originData.result.car_option;
    const [isDetailOptionVisible, setIsDetailOptionVisible] = useState<boolean>(false);

    function onDetailOptionModal() {
        if (!isDetailOptionVisible) document.body.style.overflow = 'hidden';
        setIsDetailOptionVisible((prev) => !prev);
    }

    return (
        <div style={{ width: '100%', height: '100%', marginTop: '4em' }}>
            <div
                style={{
                    width: '100%',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottomWidth: 2,
                    borderColor: '#ff436c',
                    borderBottomStyle: 'solid',
                }}
            >
                <Title>차량옵션</Title>
            </div>

            {/* 주요옵션리스트 */}
            <div style={{ width: '100%', marginTop: '1.8em', marginBottom: '1.8em' }}>
                {carOptSpecial.map((item: any) => {
                    return (
                        <div
                            key={item.number}
                            style={{
                                width: '25%',
                                marginBottom: '1.5em',
                                display: 'inline-block',
                                textAlign: 'center',
                            }}
                        >
                            <CircleActiveItemWrap color={item.option_chk === '0' ? '#cfcfcf' : '#ff436c'}>
                                <img
                                    width={'55%'}
                                    height={'55%'}
                                    src={
                                        item.option_chk === '0'
                                            ? `${process.env.REACT_APP_LIVECOMMERCE_API_URL}/static/images/icon_product_option_${item.number}.svg`
                                            : `${process.env.REACT_APP_LIVECOMMERCE_API_URL}/static/images/icon_product_option_${item.number}_on.svg`
                                    }
                                />
                            </CircleActiveItemWrap>
                            <div style={{ marginTop: '0.5em' }}>
                                <CarOptionItemSpan>{item.title}</CarOptionItemSpan>
                            </div>
                        </div>
                    );
                })}
                <div style={{ marginTop: '1em' }}></div>

                {/* 옵션더보기 */}
                <OptionMoreWrap onClick={onDetailOptionModal}>
                    <OptionMoreSpan>옵션 더보기</OptionMoreSpan>
                </OptionMoreWrap>

                {/* 디테일옵션모달 */}
                <CarDetailOptionModal
                    carOptins={carOptins}
                    isVisible={isDetailOptionVisible}
                    setIsVisible={setIsDetailOptionVisible}
                />
            </div>
        </div>
    );
}

const CircleActiveItemWrap = styled.div.attrs((props: { color: string }) => props)`
    margin: 0 auto;
    border-radius: 30px;
    background-color: ${(props) => props.color};
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 430px) {
        width: 50px;
        height: 50px;
    }
`;

const CarOptionItemSpan = styled.span`
    font-size: 0.9em;
    color: ${(props) => props.theme.text.secondary};
    @media (max-width: 430px) {
        font-size: 0.75em;
    }
`;

const OptionMoreWrap = styled.div`
    width: 95%;
    height: 30px;
    background-color: #ff436c;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const OptionMoreSpan = styled.span`
    color: #ffffff;
    font-size: 0.9em;
    /* font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)}; */
`;

const Title = styled.span`
    color: #ff436c;
    font-size: 1.135em;
    font-weight: ${(props) => (props.theme.state === 'light' ? 'bold' : null)};
`;

export default CarDetailOption;
