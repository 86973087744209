const liveCommerceDomain = process.env.REACT_APP_LIVECOMMERCE_API_URL;

// 믿차 차량상세 API
export const meetchaCarDetailAPI = `${liveCommerceDomain}/meetcha/car/v2/carDetailJson`;
// 믿차 가격비교 API
export const meetchaCarCompareAPI = `${liveCommerceDomain}/meetcha/car/v2/comparePrice`;
// 믿차 방송리스트 API
export const meetchaBroadcastListAPI = `${liveCommerceDomain}/meetcha/v3/broadcast/list`;
// 믿차 배너 API
export const meetchaBannerAPI = `${liveCommerceDomain}/meetcha/car/v2/getBannerInfo`;
// 믿차 방송디테일 API
export const meetchaBroadcastDetailAPI = `${liveCommerceDomain}/meetcha/v3/broadcast/detail`;
