import { Fragment, useState } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './CarImageSlider.css';
import { isMobile } from 'react-device-detect';
// import SimpleImageSlider from 'react-simple-image-slider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCreative, EffectFade, Thumbs, FreeMode } from 'swiper';
import defaultCarImage from '../../../assets/images/defaultImage.jpg';
import { ReactComponent as Play } from '../../../assets/images/play2.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useMclid } from '../../../context/Providers';

type CarImageSliderProps = {
    originData: any;
};

function CarImageSlider({ originData }: CarImageSliderProps) {
    const navigate = useNavigate();
    const [mclid, settingMclid] = useMclid();
    const delay = 3000;
    const carData = originData.result.data[0];
    const videoList = originData.result.video_list;
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    let carImagesArr: any = [];

    // react-simple-image-slider
    // for (let i = 1; i < 21; i++) {
    //     if (carData.img1 === '' || carData.img2 === '') {
    //         carImagesArr.push({ url: defaultCarImage });
    //         break;
    //     }
    //     if (carData[`img${i}`] !== '' && carData[`img${i}`].includes('d2rxlfguhx92xl')) {
    //         carImagesArr.push({ url: `${carData[`img${i}`]}&w=500` });
    //     } else if (carData[`img${i}`] !== '') {
    //         carImagesArr.push({ url: carData[`img${i}`] });
    //     }
    // }
    // function onStartSlide(index: number) {
    //     setIndexState(index);
    // }

    for (let i = 1; i < 21; i++) {
        if (carData.img1 === '' || carData.img2 === '') {
            carImagesArr.push(defaultCarImage);
            break;
        }
        if (carData[`img${i}`] !== '' && carData[`img${i}`].includes('d2rxlfguhx92xl')) {
            carImagesArr.push(`${carData[`img${i}`]}&w=500`);
        } else if (carData[`img${i}`] !== '') {
            carImagesArr.push(carData[`img${i}`]);
        }
    }

    const [indexState, setIndexState] = useState<number>(1);
    const [lengthState, setLengState] = useState<number>(carImagesArr.length);

    function handleImgOnError(e: any) {
        e.target.src = defaultCarImage;
    }

    function onPlayImgClick() {
        if (videoList.length > 0) {
            navigate(
                `/broadcast/detail?mclid=${mclid}&seq=${videoList[0].seq}&liveType=${videoList[0].live_type}&wdtbSttus=${videoList[0].wdtb_sttus}`,
            );
            // window.open(
            //     `/broadcast/detail?seq=${videoList[0].seq}&liveType=${videoList[0].live_type}&wdtbSttus=${videoList[0].wdtb_sttus}`,
            // );
        }
    }

    return (
        <Fragment>
            {isMobile ? (
                <SliderWrap>
                    <Swiper
                        loop={true}
                        grabCursor={true}
                        centeredSlides={true}
                        effect={'fade'}
                        modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        onSlideChange={(swiper) => setIndexState(swiper.realIndex + 1)}
                    >
                        {carImagesArr.map((item: string) => {
                            return (
                                <SwiperSlide key={item}>
                                    <img src={item} width={'100%'} height={'100%'} onError={handleImgOnError} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    {videoList.length > 0 && indexState === 1 ? (
                        <PlayImgWrap onClick={onPlayImgClick} bol={carImagesArr[0].includes('defaultImage')}>
                            <Play width={'90%'} height={'90%'} />
                        </PlayImgWrap>
                    ) : null}
                    {!carImagesArr[0].includes('defaultImage') ? (
                        <CountWrap>
                            <CountSpan>{`${indexState}/${lengthState}`}</CountSpan>
                        </CountWrap>
                    ) : null}
                </SliderWrap>
            ) : (
                <Fragment>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Swiper
                            grabCursor={true}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                            onSlideChange={(swiper) => setIndexState(swiper.realIndex + 1)}
                        >
                            {carImagesArr.map((item: string) => {
                                return (
                                    <SwiperSlide key={item}>
                                        <img src={item} width={'100%'} height={'100%'} onError={handleImgOnError} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        {videoList.length > 0 && indexState === 1 ? (
                            <PlayImgWrap onClick={onPlayImgClick} bol={carImagesArr[0].includes('defaultImage')}>
                                <Play width={'90%'} height={'90%'} />
                            </PlayImgWrap>
                        ) : null}

                        {!carImagesArr[0].includes('defaultImage') ? (
                            <CountWrap>
                                <CountSpan>{`${indexState}/${lengthState}`}</CountSpan>
                            </CountWrap>
                        ) : null}
                    </div>
                    {carImagesArr.length === 1 && carImagesArr[0].includes('defaultImage') ? null : (
                        <Swiper
                            grabCursor={true}
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                            style={{ paddingTop: 10 }}
                        >
                            {carImagesArr.map((item: string) => {
                                return (
                                    <SwiperSlide key={item}>
                                        <img src={item} width={'100%'} height={'100%'} onError={handleImgOnError} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                </Fragment>
            )}
        </Fragment>

        // react-simple-image-slider
        // <SliderWrap>
        //     <SimpleImageSlider
        //         width={'100%'}
        //         height={'100%'}
        //         images={carImagesArr}
        //         showBullets={false}
        //         showNavs={!carImagesArr[0].url.includes('defaultImage')}
        //         onStartSlide={onStartSlide}
        //         // autoPlay={true}
        //         style={{ position: 'absolute' }}
        //         navSize={35}
        //         navMargin={5}
        //         autoPlayDelay={4}
        //     />
        //     {videoList.length > 0 && indexState === 1 ? (
        //         <PlayImgWrap onClick={onPlayImgClick} bol={carImagesArr[0].url.includes('defaultImage')}>
        //             <Play width={'90%'} height={'90%'} />
        //         </PlayImgWrap>
        //     ) : null}
        //     {!carImagesArr[0].url.includes('defaultImage') ? (
        //         <CountWrap>
        //             <CountSpan>{`${indexState}/${lengthState}`}</CountSpan>
        //         </CountWrap>
        //     ) : null}
        // </SliderWrap>
    );
}

const SliderWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 438px;
    @media (max-width: 430px) {
        height: 316px;
    }
    @media (max-width: 376px) {
        height: 250px;
    }
`;

const PlayImgWrap = styled.div.attrs((props: { bol: boolean }) => props)`
    z-index: 1;
    width: 80px;
    height: 80px;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: ${(props) => (props.bol ? 'rgba(0, 0, 0, 0.5)' : null)};
    animation: fadein 0.5s linear forwards;
    @media (max-width: 430px) {
        width: 65px;
        height: 65px;
    }
    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const CountWrap = styled.div`
    z-index: 1;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    right: 10px;
    bottom: 10px;
    width: 70px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 4px;
    padding-bottom: 5px;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: 430px) {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        right: 10px;
        bottom: 10px;
        width: 60px;
        padding: 5px 8px;
    }
`;

const CountSpan = styled.span`
    color: #ffffff;
    font-size: 1.1em;
    @media (max-width: 430px) {
        font-size: 0.95em;
    }
`;

export default CarImageSlider;
